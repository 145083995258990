import React, { useEffect, useState, useMemo } from 'react';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import {
  Box,
  Paper,
  Typography,
  IconButton,
  Avatar,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  Alert,
  SwipeableDrawer,
  Divider,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  MenuItem,
  FormControl,
  Select,
  InputLabel
} from '@mui/material';

import {
  ExpandMore,
  FilterList,
  Edit,
  Delete as DeleteIcon,
  Launch,
  Close
} from '@mui/icons-material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { useBigBoardTable } from '../BigBoardTableContext';
import ListboxComponent from '../components/ListboxComponent ';

/** Retourne les initiales d'un nom + prénom */
const getInitials = (nom, prenom) => {
  const firstInitial = nom ? nom[0] : '';
  const secondInitial = prenom ? prenom[0] : '';
  return (firstInitial + secondInitial).toUpperCase();
};

/** Génère une couleur de fond pour un avatar en fonction d'une chaîne de caractères */
const getAvatarColor = (name) => {
  if (!name) return '#757575';
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash % 360);
  return `hsl(${hue}, 70%, 50%)`;
};

/** Retourne la couleur de texte (noir ou blanc) la plus contrastée selon la couleur de fond */
const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

/** Joint les chaînes non vides par un tiret */
const joinWithDash = (...items) => items.filter(Boolean).join(' - ');

// ====================================
// =========== AVATAR COMPONENT =======
// ====================================
const UserAvatar = ({ user, mediaUrl, size = 24 }) => (
  <Tooltip
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'white',
          '& .MuiTooltip-arrow': { color: 'white' },
          boxShadow: '0px 2px 8px rgba(0,0,0,0.15)'
        }
      }
    }}
    title={
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar
          src={`${mediaUrl}/users/${user.id}.png`}
          sx={{ width: 40, height: 40 }}
        />
        <Box>
          <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
            {user.prenom} {user.nom}
          </Typography>
        </Box>
      </Box>
    }
  >
    <Avatar
      sx={{
        width: size,
        height: size,
        fontSize: `${size * 0.5}px`,
        bgcolor: getAvatarColor(user.nom + user.prenom)
      }}
    >
      {getInitials(user.prenom, user.nom)}
    </Avatar>
  </Tooltip>
);

// ====================================
// =========== PLAYER CARD ============
// ====================================
const PlayerCard = ({
  player,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  disablePlayerLink
}) => {
  // Ligne "Poste - année_naissance - taille"
  const line2 = joinWithDash(
    player.poste,
    player.annee_naissance,
    player.taille ? `${player.taille}cm` : null
  );

  // Exemple : "Francés (FRA) - Zalgiris Kaunas - LKL - EuroLeague"
  const passportPart = player.nationalite
    ? player.nationalite + (player.passport ? ` (${player.passport})` : '')
    : '';
  const line3 = joinWithDash(
    passportPart,
    player.team,
    [player.league_1, player.league_2, player.competicion_europa]
      .filter(Boolean)
      .join(' - ')
  );

  return (
    <ListItem
      button
      onClick={!disablePlayerLink ? () => onPlayerClick(player) : undefined}
      sx={{
        mb: 1,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: disablePlayerLink ? 'background.paper' : 'action.hover'
        },
        py: 2,
        cursor: disablePlayerLink ? 'default' : 'pointer'
      }}
    >
      <ListItemAvatar>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Classement */}
          <Typography color="textSecondary" sx={{ minWidth: '25px' }}>
            {player.classement}.
          </Typography>
          {/* Avatar du joueur */}
          <Avatar
            src={`${mediaUrl}/photos/${player.id}.png`}
            alt={player.nom}
            sx={{ width: 48, height: 48 }}
          />
        </Box>
      </ListItemAvatar>

      <Grid container spacing={2} sx={{ ml: 1 }}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            {/* Nom du joueur */}
            <Typography
              variant="subtitle1"
              color="text.primary"
              component="span"
              sx={{
                fontSize: '1.1rem',
                ...(disablePlayerLink
                  ? {}
                  : {
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.main',
                        textDecoration: 'underline'
                      }
                    })
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (!disablePlayerLink) onPlayerClick(player);
              }}
            >
              {player.nom}
            </Typography>

            {/* Ligne 2 */}
            {line2 && (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontSize: '1rem' }}
              >
                {line2}
              </Typography>
            )}

            {/* Ligne 3 */}
            {line3 && (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontSize: '1rem' }}
              >
                {line3}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Lien externe si player.url existe et si non disable */}
      {player.url && !disablePlayerLink && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            size="small"
            onClick={(e) => onPlayerUrlClick(player.url, e)}
          >
            <Launch fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

// ====================================
// =========== BOARD CONTENT ==========
// ====================================
const BoardContent = ({
  board,
  onEdit,
  onDelete,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  disablePlayerLink
}) => (
  <Paper sx={{ p: 2, mb: 2 }}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <UserAvatar user={board.user} mediaUrl={mediaUrl} />
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {board.libelle}
        </Typography>
      </Box>

      <Box>
        <IconButton
          size="small"
          onClick={() => onEdit(board.id)}
          color="primary"
          sx={{ mr: 1 }}
        >
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={() => onDelete(board)} color="error">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>

    <List disablePadding>
      {board.players
        .sort((a, b) => a.classement - b.classement)
        .map((player) => (
          <PlayerCard
            key={player.id}
            player={player}
            onPlayerClick={onPlayerClick}
            onPlayerUrlClick={onPlayerUrlClick}
            mediaUrl={mediaUrl}
            disablePlayerLink={disablePlayerLink}
          />
        ))}
    </List>
  </Paper>
);

// ====================================
// =========== TAG ACCORDION ==========
// ====================================
const TagAccordion = ({
  tag,
  boards,
  onEdit,
  onDelete,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  expandedTags,
  setExpandedTags,
  disablePlayerLink,
  onGeneratePDFForTag
}) => {
  // Vérifie si l'accordéon de ce tag est ouvert
  const isExpanded = expandedTags.has(tag.id);

  const handleChange = () => {
    const newSet = new Set(expandedTags);
    if (newSet.has(tag.id)) {
      newSet.delete(tag.id);
    } else {
      newSet.add(tag.id);
    }
    setExpandedTags(newSet);
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleChange} sx={{ mb: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          bgcolor: 'background.default',
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        {/* On sépare le Chip (nom du tag) et le bouton PDF de chaque côté */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Chip
            label={tag.libelle}
            style={{
              backgroundColor: tag.code_couleur,
              color: getContrastColor(tag.code_couleur),
              fontSize: '1.25rem',
              height: '48px',
              borderRadius: '8px'
            }}
          />

          <Button
            variant="contained"
            startIcon={<PictureAsPdfIcon />}
            onClick={(e) => {
              e.stopPropagation(); // Pour éviter de toggler l'accordéon
              onGeneratePDFForTag(tag, boards);
            }}
          >
            PDF
          </Button>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {boards.map((board) => {
            const nbBoards = boards.length;
            let mdSize;
            if (nbBoards === 1) mdSize = 12;
            else if (nbBoards === 2) mdSize = 6;
            else mdSize = 4; // Pour 3 boards ou plus

            return (
              <Grid item xs={12} md={mdSize} key={board.id}>
                <BoardContent
                  board={board}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onPlayerClick={onPlayerClick}
                  onPlayerUrlClick={onPlayerUrlClick}
                  mediaUrl={mediaUrl}
                  disablePlayerLink={disablePlayerLink}
                />
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

// ====================================
// =========== FILTER DRAWER ==========
// (pour version mobile)
// ====================================
const FilterDrawer = ({
  open,
  onClose,
  players,
  users,
  selectedPlayer,
  selectedUser,
  onPlayerChange,
  onUserChange,
  mediaUrl,
  tags,
  selectedTags,
  onTagsChange,
  userRole,
  bigBoardTypeFilter,
  setBigBoardTypeFilter
}) => {
  const isSuperAdmin = userRole === 'super admin';

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
          pb: 2
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          {/* Filtre Joueur */}
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={players.players || []}
              getOptionLabel={(player) => player?.nom || ''}
              value={selectedPlayer}
              onChange={onPlayerChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Jugador" />
              )}
              renderOption={(props, player) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Avatar
                    src={`${mediaUrl}/photos/${player.id}.png`}
                    alt={player.nom}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography noWrap>{player.nom}</Typography>
                </Box>
              )}
              ListboxComponent={ListboxComponent}
              clearOnEscape
            />
          </Grid>

          {/* Filtre Utilisateur */}
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={users}
              getOptionLabel={(user) =>
                user ? `${user.prenom} ${user.nom}` : ''
              }
              value={selectedUser}
              onChange={onUserChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Usuario" />
              )}
              renderOption={(props, user) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <UserAvatar user={user} mediaUrl={mediaUrl} size={32} />
                  <Typography noWrap>{`${user.prenom} ${user.nom}`}</Typography>
                </Box>
              )}
              clearOnEscape
            />
          </Grid>

          {/* Filtre Tags */}
          <Grid item xs={12}>
            <Autocomplete
              multiple
              fullWidth
              options={tags}
              getOptionLabel={(tag) => tag.libelle}
              value={selectedTags}
              onChange={onTagsChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Tags" />
              )}
              renderOption={(props, tag) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Chip
                    size="small"
                    label={tag.libelle}
                    style={{
                      backgroundColor: tag.code_couleur,
                      color: getContrastColor(tag.code_couleur)
                    }}
                  />
                </Box>
              )}
              renderTags={(value, getTagProps) =>
                value.map((tag, index) => (
                  <Chip
                    key={tag.id}
                    size="small"
                    label={tag.libelle}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: tag.code_couleur,
                      color: getContrastColor(tag.code_couleur)
                    }}
                  />
                ))
              }
              clearOnEscape
            />
          </Grid>

          {/* Si superadmin => filtre sur type (Pro / Cantera / Todos) */}
          {isSuperAdmin && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="type-filter-label">Filtrar por tipo</InputLabel>
                <Select
                  labelId="type-filter-label"
                  value={bigBoardTypeFilter}
                  label="Filtrar por tipo"
                  onChange={(e) => setBigBoardTypeFilter(e.target.value)}
                >
                  <MenuItem value="all">Todos</MenuItem>
                  <MenuItem value="pro">Pro</MenuItem>
                  <MenuItem value="cantera">Cantera</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

// =====================================
// =========== addPageTemplate =========
// (Header/Footer du PDF)
// =====================================
const addPageTemplate = (doc) => {
  const pageNumber = doc.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;

  // Dessiner le cadre (de 10 à pageHeight - 10)
  doc.setDrawColor('#EA7F00');
  doc.setLineWidth(2);
  doc.rect(10, 10, pageWidth - 20, pageHeight - 20);

  // Dessiner la bande orange en bas
  const bandHeight = 20;
  const bandY = pageHeight - 10 - bandHeight; // marge inférieure = 10
  doc.setFillColor(234, 127, 0);
  doc.rect(10, bandY, pageWidth - 20, bandHeight, 'F');

  // Ajouter logos, texte et numéro de page dans la bande
  const logoSize = 16;
  try {
    const logoY = bandY + (bandHeight - logoSize) / 2;

    // Logo de Valencia Basket à gauche
    doc.addImage(
      `${process.env.PUBLIC_URL}/assets/LogoValenciaBasket.png`,
      'PNG',
      15,
      logoY,
      logoSize,
      logoSize
    );

    // Texte centré en blanc
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(9);
    doc.text(
      `VALENCIA BASKET - DEPARTAMENTO DE SCOUTING`,
      pageWidth / 2,
      bandY + bandHeight / 2 + 3,
      { align: 'center' }
    );

    // Logo d'Alquería à droite
    doc.addImage(
      `${process.env.PUBLIC_URL}/assets/alqueria-del-basket.png`,
      'PNG',
      pageWidth - 15 - logoSize,
      logoY,
      logoSize,
      logoSize
    );

    // Numéro de page centré
    doc.setFontSize(8);
    doc.text(
      pageNumber.toString(),
      pageWidth / 2,
      bandY + bandHeight - 3,
      { align: 'center' }
    );
  } catch (error) {
    console.error('Error adding images to PDF page:', error);
  }

  // Réinitialiser la couleur du texte en noir
  doc.setTextColor(0);
};

// ====================================
// =========== BIG BOARD TABLE ========
// ====================================
const BigBoardTable = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  // On récupère les infos d'utilisateur dans le localStorage
  const userData = JSON.parse(localStorage.getItem('user'));
  const currentUserId = userData?.id || null;
  const userRole = (userData?.role || '').toLowerCase();

  // Filtre de type pour superadmin
  const [bigBoardTypeFilter, setBigBoardTypeFilter] = useState('all');

  // État local
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [boardToDelete, setBoardToDelete] = useState(null);
  const [players, setPlayers] = useState([]);
  const [users, setUsers] = useState([]);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  // État local pour gérer l'expansion des accordéons
  const [expandedTags, setExpandedTags] = useState(new Set());
  // État local pour la sélection de plusieurs tags
  const [selectedTags, setSelectedTags] = useState([]);

  // Contexte (depuis BigBoardTableContext)
  const {
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
    selectedPlayer,
    setSelectedPlayer,
    selectedUser,
    setSelectedUser,
    tags,
    setTags
  } = useBigBoardTable();

  // État pour stocker les query params (restauration)
  const [pendingPlayerId, setPendingPlayerId] = useState(null);
  const [pendingUserId, setPendingUserId] = useState(null);
  const [pendingTagIds, setPendingTagIds] = useState([]);

  // =====================================================
  // =============== APPEL API ============================
  // =====================================================
  const refreshData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${backendUrl}/api/bigboard`, {
        params: { section }
      });
      setData(response.data);
      setError(null);
    } catch (error) {
      console.error('Error al cargar los Big Boards:', error);
      setError('No se pueden cargar los Big Boards.');
    } finally {
      setLoading(false);
    }
  };

  // =====================================================
  // 1) Au montage : on lit les query params
  // =====================================================
  useEffect(() => {
    const playerId = searchParams.get('playerId');
    const userId = searchParams.get('userId');
    const tagIds = searchParams.get('tagIds');
    const expandedParam = searchParams.get('expanded');

    if (playerId) setPendingPlayerId(parseInt(playerId, 10));
    if (userId) setPendingUserId(parseInt(userId, 10));

    if (tagIds) {
      const tagsToRestore = tagIds.split(',').map((id) => parseInt(id, 10));
      setPendingTagIds(tagsToRestore);
    }

    if (expandedParam) {
      const ids = expandedParam
        .split(',')
        .filter((id) => id.trim() !== '')
        .map((id) => parseInt(id, 10));
      setExpandedTags(new Set(ids));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =====================================================
  // 2) Charge data initiale une seule fois
  // =====================================================
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [tagsResponse, playersResponse, usersResponse] = await Promise.all([
          axios.get(`${backendUrl}/api/tags`, { params: { section } }),
          axios.get(`${backendUrl}/api/players/select`, { params: { section } }),
          axios.get(`${backendUrl}/api/users`, { params: { section } })
        ]);

        setTags(tagsResponse.data);
        setPlayers(playersResponse.data);
        setUsers(Array.isArray(usersResponse.data) ? usersResponse.data : []);

        // Restauration du joueur
        if (pendingPlayerId && playersResponse.data.players) {
          const foundP = playersResponse.data.players.find(
            (p) => p.id === pendingPlayerId
          );
          if (foundP) setSelectedPlayer(foundP);
        }

        // Restauration de l’utilisateur
        if (pendingUserId && usersResponse.data) {
          const foundU = usersResponse.data.find((u) => u.id === pendingUserId);
          if (foundU) setSelectedUser(foundU);
        }

        // Restauration des tags
        if (pendingTagIds.length > 0 && tagsResponse.data) {
          const foundTags = tagsResponse.data.filter((tag) =>
            pendingTagIds.includes(tag.id)
          );
          if (foundTags.length > 0) setSelectedTags(foundTags);
        }
      } catch (error) {
        console.error('Error al cargar los datos iniciales:', error);
      }
      // Ensuite, on rafraîchit la data
      refreshData();
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =====================================================
  // 3) Re-fetch si on revient sur la page (focus)
  // =====================================================
  useEffect(() => {
    const handleFocus = () => {
      refreshData();
    };
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  // =====================================================
  // =============== HANDLERS CRUD =======================
  // =====================================================
  const handleEditClick = (boardId) => {
    navigate(`/bigboard/edit/${boardId}`);
  };

  const handleDelete = async (boardId) => {
    try {
      await axios.delete(`${backendUrl}/api/bigboard/${boardId}`, {
        params: { section }
      });
      await refreshData();
      setOpenDeleteDialog(false);
      setBoardToDelete(null);
    } catch (error) {
      console.error('Error al eliminar el Big Board:', error);
      setError('Error al eliminar el Big Board.');
    }
  };

  const handlePlayerClick = (player) => {
    navigate(`/players/${player.id}`);
  };

  const handlePlayerUrlClick = (url, event) => {
    event.stopPropagation();
    window.open(url, '_blank');
  };

  // =====================================================
  // ============== FILTRES LOCAUX (memo) ================
  // =====================================================
  const filteredBoards = useMemo(() => {
    if (!data) return [];

    return data.filter((board) => {
      // 1) Filtre par type, selon le rôle
      const boardTypeLower = board.type ? board.type.toLowerCase() : '';

      let passTypeCheck = true;
      if (userRole === 'super admin') {
        if (bigBoardTypeFilter === 'pro') {
          passTypeCheck = boardTypeLower === 'pro';
        } else if (bigBoardTypeFilter === 'cantera') {
          passTypeCheck = boardTypeLower === 'cantera';
        }
      } else if (userRole === 'admin pro') {
        passTypeCheck = boardTypeLower === 'pro';
      } else if (userRole === 'admin cantera') {
        passTypeCheck = boardTypeLower === 'cantera';
      } else if (userRole === 'entrenador' || userRole === 'pdp') {
        passTypeCheck = board.user.id === currentUserId;
      }

      if (!passTypeCheck) return false;

      // 2) Filtre joueur
      const matchesPlayer =
        !selectedPlayer || board.players.some((p) => p.id === selectedPlayer.id);

      // 3) Filtre user
      const matchesUser = !selectedUser || board.user.id === selectedUser.id;

      // 4) Filtre tags
      const matchesTags =
        selectedTags.length === 0 ||
        board.tags.some((boardTag) =>
          selectedTags.some((st) => st.id === boardTag.id)
        );

      return matchesPlayer && matchesUser && matchesTags;
    });
  }, [
    data,
    selectedPlayer,
    selectedUser,
    selectedTags,
    bigBoardTypeFilter,
    userRole,
    currentUserId
  ]);

  // Regrouper par tag
  const groupedByTag = useMemo(() => {
    if (!tags) return [];
    const groups = new Map();
    tags.forEach((t) => {
      groups.set(t.id, { tag: t, boards: [] });
    });
    filteredBoards.forEach((board) => {
      board.tags.forEach((t) => {
        if (groups.has(t.id)) {
          groups.get(t.id).boards.push(board);
        }
      });
    });
    // On ne garde que les tags ayant des boards
    return Array.from(groups.values()).filter((g) => g.boards.length > 0);
  }, [tags, filteredBoards]);

  // =====================================================
  // =========== MISE A JOUR DES URL PARAMS ==============
  // =====================================================
  const updateSearchParams = (newState) => {
    const params = new URLSearchParams(searchParams.toString());

    // On stocke l'expansion
    if (expandedTags.size > 0) {
      params.set('expanded', Array.from(expandedTags).join(','));
    } else {
      params.delete('expanded');
    }

    // Player
    if (newState.selectedPlayer !== undefined) {
      const val = newState.selectedPlayer ? newState.selectedPlayer.id : '';
      val ? params.set('playerId', val) : params.delete('playerId');
    } else if (selectedPlayer) {
      params.set('playerId', selectedPlayer.id);
    } else {
      params.delete('playerId');
    }

    // User
    if (newState.selectedUser !== undefined) {
      const val = newState.selectedUser ? newState.selectedUser.id : '';
      val ? params.set('userId', val) : params.delete('userId');
    } else if (selectedUser) {
      params.set('userId', selectedUser.id);
    } else {
      params.delete('userId');
    }

    // Tags
    if (newState.selectedTags !== undefined) {
      const tagIds = newState.selectedTags.map((t) => t.id);
      if (tagIds.length > 0) {
        params.set('tagIds', tagIds.join(','));
      } else {
        params.delete('tagIds');
      }
    } else if (selectedTags.length > 0) {
      params.set(
        'tagIds',
        selectedTags.map((t) => t.id).join(',')
      );
    } else {
      params.delete('tagIds');
    }

    setSearchParams(params, { replace: true });
  };

  // Mise à jour de l’URL quand on change de sélection
  useEffect(() => {
    updateSearchParams({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayer, selectedUser, selectedTags, expandedTags]);

  // =====================================================
  // =========== GÉNÉRATION PDF PAR TAG ==================
  // =====================================================
  const generatePDFForTag = async (tag, boards) => {
    try {
      // Prépare la date (jj-mm-aaaa)
      const now = new Date();
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const year = now.getFullYear();
      const dateStr = `${day}-${month}-${year}`;

      // Création du document PDF
      const doc = new jsPDF();

      // Ajout du template (header/footer) sur la première page
      addPageTemplate(doc);

      // Titre principal
      let yPos = 20;
      doc.setFontSize(18);
      doc.setTextColor(0);
      doc.text(`Ranking - ${tag.libelle}`, doc.internal.pageSize.width / 2, yPos, {
        align: 'center'
      });
      yPos += 15;

      for (let bIndex = 0; bIndex < boards.length; bIndex++) {
        const board = boards[bIndex];

        // Vérifier l'espace restant sur la page
        if (yPos > doc.internal.pageSize.height - 50) {
          doc.addPage();
          addPageTemplate(doc);
          yPos = 20;
        }

        // Titre du board
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(0);
        doc.text(board.libelle, 20, yPos);
        yPos += 8;

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(9);
        doc.setTextColor(0);

        // Préparer les données du tableau
        const playersSorted = board.players.sort((a, b) => a.classement - b.classement);

        // Construire le contenu dans une seule colonne "Infos"
        const rowDataArray = playersSorted.map((p) => {
          const infos = [];
          if (p.poste) infos.push(p.poste);
          if (p.annee_naissance) infos.push(p.annee_naissance);
          if (p.taille) infos.push(`${p.taille}cm`);
          if (p.nationalite) {
            const nat = p.passport ? `${p.nationalite} (${p.passport})` : p.nationalite;
            infos.push(nat);
          }
          if (p.team) infos.push(p.team);
          const leagues = [p.league_1, p.league_2, p.competicion_europa].filter(Boolean);
          if (leagues.length > 0) {
            infos.push(leagues.join(' - '));
          }

          const infoLine = infos.join(' - ');
          return [p.classement, p.nom, infoLine];
        });

        // Génération du tableau avec lignes de séparation (theme: 'grid')
        doc.autoTable({
          startY: yPos,
          head: [['#', 'Nombre', 'Infos']],
          body: rowDataArray,
          theme: 'grid', // Grille pour bien séparer
          headStyles: {
            fillColor: [234, 127, 0],
            textColor: [255, 255, 255], // texte en blanc pour l'en-tête
            fontSize: 9,
            fontStyle: 'bold'
          },
          styles: {
            fontSize: 8,
            cellPadding: 2,
            overflow: 'linebreak'
          },
          margin: { left: 15, right: 15, bottom: 30 },
          didDrawPage: () => {
            // À chaque nouvelle page, redessiner le template
            addPageTemplate(doc);
          }
        });

        yPos = doc.lastAutoTable.finalY + 10;
      }

      // Nom du fichier
      doc.save(`VBC_Ranking_${tag.libelle}_${dateStr}.pdf`);
    } catch (error) {
      console.error('Erreur lors de la génération du PDF:', error);
    }
  };

  // =====================================================
  // =========== RENDU ==================================
  // =====================================================
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        No hay Rankings disponibles
      </Alert>
    );
  }

  // Pour les rôles "entrenador" / "pdp" : désactiver le lien vers la fiche joueur
  const disablePlayerLink = userRole === 'entrenador' || userRole === 'pdp';

  return (
    <>
      <Paper elevation={2}>
        <Box sx={{ p: 2, pb: isMobile ? 7 : 2 }}>
          {/* BARRE DE FILTRES (Desktop uniquement) */}
          {!isMobile && (
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {/* Filtre Joueur */}
              <Grid item xs={12} md={4}>
                <Autocomplete
                  fullWidth
                  options={players.players || []}
                  getOptionLabel={(p) => p?.nom || ''}
                  value={selectedPlayer}
                  onChange={(_, newValue) => {
                    setSelectedPlayer(newValue);
                    updateSearchParams({ selectedPlayer: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Filtrar por Jugador"
                    />
                  )}
                  renderOption={(props, player) => (
                    <Box
                      component="li"
                      {...props}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <Avatar
                        src={`${mediaUrl}/photos/${player.id}.png`}
                        alt={player.nom}
                        sx={{ width: 32, height: 32 }}
                      />
                      <Typography noWrap>{player.nom}</Typography>
                    </Box>
                  )}
                  ListboxComponent={ListboxComponent}
                  clearOnEscape
                />
              </Grid>

              {/* Filtre Utilisateur */}
              <Grid item xs={12} md={4}>
                <Autocomplete
                  fullWidth
                  options={users}
                  getOptionLabel={(u) => (u ? `${u.prenom} ${u.nom}` : '')}
                  value={selectedUser}
                  onChange={(_, newValue) => {
                    setSelectedUser(newValue);
                    updateSearchParams({ selectedUser: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Filtrar por Usuario"
                    />
                  )}
                  renderOption={(props, user) => (
                    <Box
                      component="li"
                      {...props}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <UserAvatar user={user} mediaUrl={mediaUrl} size={32} />
                      <Typography noWrap>{`${user.prenom} ${user.nom}`}</Typography>
                    </Box>
                  )}
                  clearOnEscape
                />
              </Grid>

              {/* Filtre Tags + (pour superadmin) Filtre type */}
              {userRole === 'super admin' ? (
                <>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      multiple
                      fullWidth
                      options={tags}
                      getOptionLabel={(t) => t.libelle}
                      value={selectedTags}
                      onChange={(_, newValue) => {
                        setSelectedTags(newValue);
                        updateSearchParams({ selectedTags: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Filtrar por Tags"
                        />
                      )}
                      renderOption={(props, t) => (
                        <Box
                          component="li"
                          {...props}
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Chip
                            size="small"
                            label={t.libelle}
                            style={{
                              backgroundColor: t.code_couleur,
                              color: getContrastColor(t.code_couleur)
                            }}
                          />
                        </Box>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((tag, index) => (
                          <Chip
                            key={tag.id}
                            size="small"
                            label={tag.libelle}
                            {...getTagProps({ index })}
                            style={{
                              backgroundColor: tag.code_couleur,
                              color: getContrastColor(tag.code_couleur)
                            }}
                          />
                        ))
                      }
                      clearOnEscape
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        value={bigBoardTypeFilter}
                        onChange={(e) => setBigBoardTypeFilter(e.target.value)}
                      >
                        <MenuItem value="all">Todos</MenuItem>
                        <MenuItem value="pro">Pro</MenuItem>
                        <MenuItem value="cantera">Cantera</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    multiple
                    fullWidth
                    options={tags}
                    getOptionLabel={(t) => t.libelle}
                    value={selectedTags}
                    onChange={(_, newValue) => {
                      setSelectedTags(newValue);
                      updateSearchParams({ selectedTags: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Filtrar por Tags"
                      />
                    )}
                    renderOption={(props, t) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Chip
                          size="small"
                          label={t.libelle}
                          style={{
                            backgroundColor: t.code_couleur,
                            color: getContrastColor(t.code_couleur)
                          }}
                        />
                      </Box>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((tag, index) => (
                        <Chip
                          key={tag.id}
                          size="small"
                          label={tag.libelle}
                          {...getTagProps({ index })}
                          style={{
                            backgroundColor: tag.code_couleur,
                            color: getContrastColor(tag.code_couleur)
                          }}
                        />
                      ))
                    }
                    clearOnEscape
                  />
                </Grid>
              )}
            </Grid>
          )}

          {/* Affichage du nombre de Tags avec au moins 1 board */}
          <Box
            sx={{
              mx: 2,
              mt: 2,
              mb: 1,
              display: 'flex',
              alignItems: 'center',
              p: 2,
              bgcolor: theme.palette.primary.main,
              color: 'white',
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            <Box
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 2,
                fontWeight: 'bold',
                fontSize: '1.1rem'
              }}
            >
              {groupedByTag.length}
            </Box>
            <Typography variant="subtitle1">Rankings Encontrados</Typography>
          </Box>

          {/* LISTE DES ACCORDÉONS */}
          {groupedByTag.map(({ tag, boards }) => (
            <TagAccordion
              key={tag.id}
              tag={tag}
              boards={boards}
              onEdit={handleEditClick}
              onDelete={(board) => {
                setBoardToDelete(board);
                setOpenDeleteDialog(true);
              }}
              onPlayerClick={handlePlayerClick}
              onPlayerUrlClick={handlePlayerUrlClick}
              mediaUrl={mediaUrl}
              expandedTags={expandedTags}
              setExpandedTags={setExpandedTags}
              disablePlayerLink={disablePlayerLink}
              onGeneratePDFForTag={generatePDFForTag}
            />
          ))}

          {/* BOUTON FLOTTANT (Mobile) */}
          {isMobile && (
            <Fab
              color="primary"
              sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: theme.zIndex.fab
              }}
              onClick={() => setOpenFilterDrawer(true)}
            >
              <FilterList />
            </Fab>
          )}
        </Box>

        {/* DRAWER de FILTRES (Mobile) */}
        <FilterDrawer
          open={openFilterDrawer}
          onClose={() => setOpenFilterDrawer(false)}
          players={players}
          users={users}
          selectedPlayer={selectedPlayer}
          selectedUser={selectedUser}
          onPlayerChange={(_, newValue) => {
            setSelectedPlayer(newValue);
            updateSearchParams({ selectedPlayer: newValue });
          }}
          onUserChange={(_, newValue) => {
            setSelectedUser(newValue);
            updateSearchParams({ selectedUser: newValue });
          }}
          mediaUrl={mediaUrl}
          tags={tags}
          selectedTags={selectedTags}
          onTagsChange={(_, newValue) => {
            setSelectedTags(newValue);
            updateSearchParams({ selectedTags: newValue });
          }}
          userRole={userRole}
          bigBoardTypeFilter={bigBoardTypeFilter}
          setBigBoardTypeFilter={setBigBoardTypeFilter}
        />

        {/* DIALOG DE SUPPRESSION */}
        <Dialog
          open={openDeleteDialog}
          onClose={() => {
            setOpenDeleteDialog(false);
            setBoardToDelete(null);
          }}
        >
          <DialogTitle>Confirmar eliminación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Está seguro de que desea eliminar el Big Board "
              {boardToDelete?.libelle}"? Esta acción no se puede deshacer.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDeleteDialog(false);
                setBoardToDelete(null);
              }}
            >
              Cancelar
            </Button>
            <Button
              color="error"
              onClick={() => handleDelete(boardToDelete?.id)}
              variant="contained"
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
};

export default BigBoardTable;
