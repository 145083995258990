// components/InformacionContractual.js

import React from 'react';
import {
  Paper,
  Typography,
  Box,
  Grid,
  TextField,
  Autocomplete,
  IconButton
} from '@mui/material';
import { Clear } from '@mui/icons-material';

/**
 * Este componente muestra/edita:
 * - Agencia
 * - Rango Salarial (rangoPrecios)
 * - Contrato
 * - Buyout
 *
 * Nota: Aquí usamos Autocomplete en lugar de <Select>, porque
 *       en tu código original definiste rangos y contratos en arrays
 *       y usabas Autocomplete.
 *       Ajusta según necesites (si quieres un <Select> multiopción, etc.).
 */

function InformacionContractual({
  player,
  editablePlayer,
  setEditablePlayer,
  isEditing,
  isMobile,
  agencies = [],       // array de agencias
  priceRanges = [],    // array de rangos salariales
  contratos = [],      // array de tipos de contrato
  buyoutOptions = [],  // array de buyouts
}) {
  // Render para MOBILE
  if (isMobile) {
    return (
      <Box
        sx={{
          mb: 2,
          backgroundColor: 'background.default'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'medium',
            px: 2,
            py: 1,
            backgroundColor: 'background.paper'
          }}
        >
          5. Información Contractual
        </Typography>

        <Box sx={{ p: 2 }}>
          {isEditing ? (
            <Grid container spacing={2}>
              {/* Agencia */}
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={agencies}
                  inputValue={editablePlayer.agencia || ''}
                  onInputChange={(event, newValue) => {
                    setEditablePlayer((prev) => ({
                      ...prev,
                      agencia: newValue
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Agencia"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {editablePlayer.agencia && (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditablePlayer((prev) => ({
                                    ...prev,
                                    agencia: ''
                                  }));
                                }}
                                size="small"
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            )}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Grid>

              {/* Rango Salarial */}
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={priceRanges}
                  inputValue={editablePlayer.rangoPrecios || ''}
                  onInputChange={(event, newValue) => {
                    setEditablePlayer((prev) => ({
                      ...prev,
                      rangoPrecios: newValue
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Rango Salarial"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {editablePlayer.rangoPrecios && (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditablePlayer((prev) => ({
                                    ...prev,
                                    rangoPrecios: ''
                                  }));
                                }}
                                size="small"
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            )}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Grid>

              {/* Contrato */}
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={contratos}
                  inputValue={editablePlayer.contrato || ''}
                  onInputChange={(event, newValue) => {
                    setEditablePlayer((prev) => ({
                      ...prev,
                      contrato: newValue
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Contrato"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {editablePlayer.contrato && (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditablePlayer((prev) => ({
                                    ...prev,
                                    contrato: ''
                                  }));
                                }}
                                size="small"
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            )}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Grid>

              {/* Buyout */}
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={buyoutOptions}
                  inputValue={editablePlayer.buyout || ''}
                  onInputChange={(event, newValue) => {
                    setEditablePlayer((prev) => ({
                      ...prev,
                      buyout: newValue
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Buyout"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {editablePlayer.buyout && (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditablePlayer((prev) => ({
                                    ...prev,
                                    buyout: ''
                                  }));
                                }}
                                size="small"
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            )}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            // READ ONLY (MOBILE)
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {!!player.agencia && (
                <Typography>
                  <strong>Agencia:</strong> {player.agencia}
                </Typography>
              )}
              {!!player.rangoPrecios && (
                <Typography>
                  <strong>Rango Salarial:</strong> {player.rangoPrecios}
                </Typography>
              )}
              {!!player.contrato && (
                <Typography>
                  <strong>Contrato:</strong> {player.contrato}
                </Typography>
              )}
              {!!player.buyout && (
                <Typography>
                  <strong>Buyout:</strong> {player.buyout}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  // Render para DESKTOP
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        5. Información Contractual
      </Typography>

      {isEditing ? (
        <Grid container spacing={2}>
          {/* Agencia */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              freeSolo
              options={agencies}
              inputValue={editablePlayer.agencia || ''}
              onInputChange={(event, newValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  agencia: newValue
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Agencia"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {editablePlayer.agencia && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                agencia: ''
                              }));
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>

          {/* Rango Salarial */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              freeSolo
              options={priceRanges}
              inputValue={editablePlayer.rangoPrecios || ''}
              onInputChange={(event, newValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  rangoPrecios: newValue
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Rango Salarial"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {editablePlayer.rangoPrecios && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                rangoPrecios: ''
                              }));
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>

          {/* Contrato */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              freeSolo
              options={contratos}
              inputValue={editablePlayer.contrato || ''}
              onInputChange={(event, newValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  contrato: newValue
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Contrato"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {editablePlayer.contrato && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                contrato: ''
                              }));
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>

          {/* Buyout */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              freeSolo
              options={buyoutOptions}
              inputValue={editablePlayer.buyout || ''}
              onInputChange={(event, newValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  buyout: newValue
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buyout"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {editablePlayer.buyout && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                buyout: ''
                              }));
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      ) : (
        // READ ONLY (DESKTOP)
        <Box sx={{ mt: 1 }}>
          {(!!player.agencia ||
            !!player.rangoPrecios ||
            !!player.contrato ||
            !!player.buyout) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.agencia && (
                <Typography sx={{ display: 'flex' }}>
                  <strong style={{ minWidth: 'auto' }}>Agencia:&nbsp;</strong>
                  {player.agencia}
                </Typography>
              )}
              {!!player.rangoPrecios && (
                <Typography sx={{ display: 'flex' }}>
                  <strong style={{ minWidth: 'auto' }}>Rango Salarial:&nbsp;</strong>
                  {player.rangoPrecios}
                </Typography>
              )}
              {!!player.contrato && (
                <Typography sx={{ display: 'flex' }}>
                  <strong style={{ minWidth: 'auto' }}>Contrato:&nbsp;</strong>
                  {player.contrato}
                </Typography>
              )}
              {!!player.buyout && (
                <Typography sx={{ display: 'flex' }}>
                  <strong style={{ minWidth: 'auto' }}>Buyout:&nbsp;</strong>
                  {player.buyout}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
}

export default InformacionContractual;
