import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Avatar,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  Alert,
  SwipeableDrawer,
  Divider,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  MenuItem,
  FormControl,
  Select,
  InputLabel
} from '@mui/material';

import {
  ExpandMore,
  FilterList,
  Edit,
  Delete as DeleteIcon,
  Launch,
  Close
} from '@mui/icons-material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import axios from 'axios';
import ListboxComponent from '../components/ListboxComponent ';
import { useSeguimientoTable } from '../SeguimientoTableContext';

/** =========================================================
 *                UTILS 
 *  ========================================================= */
const getInitials = (nom, prenom) => {
  const firstInitial = nom ? nom[0] : '';
  const secondInitial = prenom ? prenom[0] : '';
  return (firstInitial + secondInitial).toUpperCase();
};

const getAvatarColor = (name) => {
  if (!name) return '#757575';
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash % 360);
  return `hsl(${hue}, 70%, 50%)`;
};

const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const joinWithDash = (...items) => items.filter(Boolean).join(' - ');

/** =========================================================
 *                AVATAR UTILISATEUR
 *  ========================================================= */
const UserAvatar = ({ user, mediaUrl, size = 24 }) => (
  <Tooltip
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'white',
          '& .MuiTooltip-arrow': { color: 'white' },
          boxShadow: '0px 2px 8px rgba(0,0,0,0.15)'
        }
      }
    }}
    title={
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar
          src={`${mediaUrl}/users/${user.id}.png`}
          sx={{ width: 40, height: 40 }}
        />
        <Box>
          <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
            {user.prenom} {user.nom}
          </Typography>
          {user.username && (
            <Typography variant="body2" sx={{ color: 'rgba(0,0,0,0.7)' }}>
              {user.username}
            </Typography>
          )}
        </Box>
      </Box>
    }
  >
    <Avatar
      sx={{
        width: size,
        height: size,
        fontSize: `${size * 0.5}px`,
        bgcolor: getAvatarColor(user.nom + user.prenom)
      }}
    >
      {getInitials(user.prenom, user.nom)}
    </Avatar>
  </Tooltip>
);

/** =========================================================
 *                PLAYER CARD
 *  ========================================================= */
const PlayerCard = ({
  player,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  disablePlayerLink
}) => {
  // Construire la ligne "Poste - annee_naissance - taille"
  const line2 = joinWithDash(
    player.poste,
    player.annee_naissance,
    player.taille ? `${player.taille}cm` : null
  );

  // Ligne "nationalite(passport) - team - leagues"
  const line3 = joinWithDash(
    player.nationalite && player.passport
      ? `${player.nationalite} (${player.passport})`
      : player.nationalite || '',
    player.team,
    [player.league_1, player.league_2, player.competicion_europa]
      .filter(Boolean)
      .join(' - ')
  );

  return (
    <ListItem
      button
      // Désactive le clic si disablePlayerLink = true
      onClick={!disablePlayerLink ? () => onPlayerClick(player) : undefined}
      sx={{
        mb: 1,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: disablePlayerLink ? 'background.paper' : 'action.hover'
        },
        py: 2,
        cursor: disablePlayerLink ? 'default' : 'pointer'
      }}
    >
      <ListItemAvatar>
        <Avatar
          src={`${mediaUrl}/photos/${player.id}.png`}
          alt={player.nom}
          sx={{ width: 48, height: 48 }}
        />
      </ListItemAvatar>

      <Box sx={{ ml: 1, flexGrow: 1 }}>
        <Typography
          variant="subtitle1"
          color="text.primary"
          sx={{
            fontSize: '1.1rem',
            ...(disablePlayerLink
              ? {}
              : {
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'underline'
                  }
                })
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (!disablePlayerLink) onPlayerClick(player);
          }}
        >
          {player.nom}
        </Typography>

        {line2 && (
          <Typography variant="body2" color="textSecondary" sx={{ fontSize: '1rem' }}>
            {line2}
          </Typography>
        )}
        {line3 && (
          <Typography variant="body2" color="textSecondary" sx={{ fontSize: '1rem' }}>
            {line3}
          </Typography>
        )}
      </Box>

      {!disablePlayerLink && player.url && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onPlayerUrlClick(player.url, e);
            }}
          >
            <Launch fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

/** =========================================================
 *                FILTER DRAWER (MOBILE)
 *  ========================================================= */
const FilterDrawer = ({
  open,
  onClose,
  players,
  users,
  selectedPlayer,
  selectedUser,
  onPlayerChange,
  onUserChange,
  mediaUrl,
  tags,
  selectedTags,
  onTagsChange,
  // Rôle + filtrage du type (Pro/Cantera)
  userRole,
  seguimientoTypeFilter,
  setSeguimientoTypeFilter
}) => {
  const isSuperAdmin = userRole === 'super admin';

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
          pb: 2
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          {/* Filtre Joueur */}
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={players.players || []}
              getOptionLabel={(player) => player?.nom || ''}
              value={selectedPlayer}
              onChange={onPlayerChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Jugador" />
              )}
              renderOption={(props, player) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Avatar
                    src={`${mediaUrl}/photos/${player.id}.png`}
                    alt={player.nom}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography noWrap>{player.nom}</Typography>
                </Box>
              )}
              ListboxComponent={ListboxComponent}
              clearOnEscape
            />
          </Grid>

          {/* Filtre Utilisateur */}
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={users}
              getOptionLabel={(user) => (user ? `${user.prenom} ${user.nom}` : '')}
              value={selectedUser}
              onChange={onUserChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Usuario" />
              )}
              renderOption={(props, user) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <UserAvatar user={user} mediaUrl={mediaUrl} size={32} />
                  <Typography noWrap>{`${user.prenom} ${user.nom}`}</Typography>
                </Box>
              )}
              clearOnEscape
            />
          </Grid>

          {/* Filtre Tags */}
          <Grid item xs={12}>
            <Autocomplete
              multiple
              fullWidth
              options={tags}
              getOptionLabel={(tag) => tag.libelle}
              value={selectedTags}
              onChange={onTagsChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Tags" />
              )}
              renderOption={(props, tag) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Chip
                    size="small"
                    label={tag.libelle}
                    style={{
                      backgroundColor: tag.code_couleur,
                      color: getContrastColor(tag.code_couleur)
                    }}
                  />
                </Box>
              )}
              renderTags={(value, getTagProps) =>
                value.map((tag, index) => (
                  <Chip
                    key={tag.id}
                    size="small"
                    label={tag.libelle}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: tag.code_couleur,
                      color: getContrastColor(tag.code_couleur)
                    }}
                  />
                ))
              }
              clearOnEscape
            />
          </Grid>

          {/* Si superadmin => on ajoute un Select pour filtrer Pro / Cantera / all */}
          {isSuperAdmin && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="type-filter-label">Filtrar por tipo</InputLabel>
                <Select
                  labelId="type-filter-label"
                  value={seguimientoTypeFilter}
                  label="Filtrar por tipo"
                  onChange={(e) => setSeguimientoTypeFilter(e.target.value)}
                >
                  <MenuItem value="all">Todos</MenuItem>
                  <MenuItem value="pro">Pro</MenuItem>
                  <MenuItem value="cantera">Cantera</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

/** =========================================================
 *  Nouveau template Header/Footer pour PDF
 *  ========================================================= */
const addPageTemplate = (doc) => {
  const pageNumber = doc.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;

  // Dessiner le cadre (de 10 à pageHeight-10)
  doc.setDrawColor('#EA7F00');
  doc.setLineWidth(2);
  doc.rect(10, 10, pageWidth - 20, pageHeight - 20);

  // Dessiner la bande orange en bas
  const bandHeight = 20;
  const bandY = pageHeight - 10 - bandHeight;
  doc.setFillColor(234, 127, 0);
  doc.rect(10, bandY, pageWidth - 20, bandHeight, 'F');

  // Ajouter logos, texte et numéro de page dans la bande
  const logoSize = 16;
  try {
    const logoY = bandY + (bandHeight - logoSize) / 2;

    // Logo Valencia Basket
    doc.addImage(
      `${process.env.PUBLIC_URL}/assets/LogoValenciaBasket.png`,
      'PNG',
      15,
      logoY,
      logoSize,
      logoSize
    );

    // Texte centré en blanc
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(9);
    doc.text(
      'VALENCIA BASKET - DEPARTAMENTO DE SCOUTING',
      pageWidth / 2,
      bandY + bandHeight / 2 + 3,
      { align: 'center' }
    );

    // Logo Alquería
    doc.addImage(
      `${process.env.PUBLIC_URL}/assets/alqueria-del-basket.png`,
      'PNG',
      pageWidth - 15 - logoSize,
      logoY,
      logoSize,
      logoSize
    );

    // Numéro de page centré
    doc.setFontSize(8);
    doc.text(
      pageNumber.toString(),
      pageWidth / 2,
      bandY + bandHeight - 3,
      { align: 'center' }
    );
  } catch (error) {
    console.error('Error adding images to PDF page:', error);
  }

  // On revient au texte en noir pour le contenu
  doc.setTextColor(0);
};

/** =========================================================
 *   ACCORDION PAR TAG (SeguimientoSection)
 *  ========================================================= */
const SeguimientoSection = ({
  tag,
  seguimientos,
  onEdit,
  onDelete,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  expandedTags,
  setExpandedTags,
  updateSearchParams,
  disablePlayerLink,
  onGeneratePDFForTag
}) => {
  const isExpanded = expandedTags.has(tag.id);

  const handleChange = () => {
    const newSet = new Set(expandedTags);
    if (newSet.has(tag.id)) {
      newSet.delete(tag.id);
    } else {
      newSet.add(tag.id);
    }
    setExpandedTags(newSet);
    updateSearchParams({ expandedTags: Array.from(newSet) });
  };

  // Nombre total de joueurs
  const totalPlayers = seguimientos.reduce((sum, s) => sum + s.players.length, 0);

  return (
    <Accordion sx={{ mb: 1 }} expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          bgcolor: 'background.default',
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        {/* On sépare la zone gauche (Chip + nb players) du bouton PDF à droite */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Chip
              label={tag.libelle}
              style={{
                backgroundColor: tag.code_couleur,
                color: getContrastColor(tag.code_couleur),
                fontSize: '1.25rem',
                height: '48px',
                borderRadius: '8px'
              }}
            />
            <Typography color="text.secondary">
              {totalPlayers} Jugador@s
            </Typography>
          </Box>

          {/* Bouton PDF */}
          <Button
            variant="contained"
            startIcon={<PictureAsPdfIcon />}
            onClick={(e) => {
              e.stopPropagation();
              onGeneratePDFForTag(tag, seguimientos);
            }}
          >
            PDF
          </Button>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 2 }}>
        {seguimientos.map((seguimiento) => (
          <Paper
            key={seguimiento.id}
            sx={{ p: 2, mb: 2, '&:last-child': { mb: 0 } }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 2
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <UserAvatar user={seguimiento.user} mediaUrl={mediaUrl} />
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  {seguimiento.libelle}
                </Typography>
              </Box>

              <Box>
                <IconButton
                  size="small"
                  onClick={() => onEdit(seguimiento.id)}
                  color="primary"
                  sx={{ mr: 1 }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => onDelete(seguimiento)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>

            <List disablePadding>
              {seguimiento.players
                .sort((a, b) => a.nom.localeCompare(b.nom))
                .map((player) => (
                  <PlayerCard
                    key={player.id}
                    player={player}
                    onPlayerClick={onPlayerClick}
                    onPlayerUrlClick={onPlayerUrlClick}
                    mediaUrl={mediaUrl}
                    disablePlayerLink={disablePlayerLink}
                  />
                ))}
            </List>
          </Paper>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

/** =========================================================
 *                SEGUIMIENTO TABLE
 *  ========================================================= */
const SeguimientoTable = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  // On récupère le user depuis localStorage
  const userData = JSON.parse(localStorage.getItem('user'));
  const currentUserId = userData?.id || null;
  const userRole = (userData?.role || '').toLowerCase();

  // Si superadmin, on peut filtrer : "all", "pro", "cantera"
  const [seguimientoTypeFilter, setSeguimientoTypeFilter] = useState('all');

  // État local
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [seguimientoToDelete, setSeguimientoToDelete] = useState(null);
  const [players, setPlayers] = useState([]);
  const [users, setUsers] = useState([]);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  // On récupère les états du contexte
  const {
    expandedTags,
    setExpandedTags,
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
    selectedPlayer,
    setSelectedPlayer,
    selectedUser,
    setSelectedUser,
    tags,
    setTags
  } = useSeguimientoTable();

  // Ajout d'un state local pour les tags sélectionnés
  const [selectedTags, setSelectedTags] = useState([]);

  // States pour restaurer les filtres depuis l'URL
  const [pendingPlayerId, setPendingPlayerId] = useState(null);
  const [pendingUserId, setPendingUserId] = useState(null);
  const [pendingTagIds, setPendingTagIds] = useState([]);

  // =========================================================
  // =============== APPEL API ===============================
  // =========================================================
  const refreshData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${backendUrl}/api/seguimiento`, {
        params: { section }
      });
      setData(response.data);
      setError(null);
    } catch (err) {
      console.error('Error al cargar los Seguimientos:', err);
      setError('No se pueden cargar los Seguimientos.');
    } finally {
      setLoading(false);
    }
  };

  // =========================================================
  // ========== LECTURE DES PARAMS URL AU MONTAGE ============
  // =========================================================
  useEffect(() => {
    const expandedParam = searchParams.get('expanded');
    if (expandedParam) {
      const ids = expandedParam
        .split(',')
        .filter((id) => id.trim() !== '')
        .map((id) => parseInt(id, 10));
      setExpandedTags(new Set(ids));
    }

    const playerId = searchParams.get('playerId');
    const userId = searchParams.get('userId');
    const tagIds = searchParams.get('tagIds');

    if (playerId) setPendingPlayerId(parseInt(playerId, 10));
    if (userId) setPendingUserId(parseInt(userId, 10));

    if (tagIds) {
      const tagsToRestore = tagIds.split(',').map((tid) => parseInt(tid, 10));
      setPendingTagIds(tagsToRestore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =========================================================
  // ========== CHARGE DATA INITIALE (tags, players, etc.) ===
  // =========================================================
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [tagsResp, playersResp, usersResp] = await Promise.all([
          axios.get(`${backendUrl}/api/tags`, { params: { section } }),
          axios.get(`${backendUrl}/api/players/select`, { params: { section } }),
          axios.get(`${backendUrl}/api/users`, { params: { section } })
        ]);

        setTags(tagsResp.data);
        setPlayers(playersResp.data);
        setUsers(Array.isArray(usersResp.data) ? usersResp.data : []);

        // Restauration du joueur
        if (pendingPlayerId && playersResp.data.players) {
          const foundP = playersResp.data.players.find(
            (p) => p.id === pendingPlayerId
          );
          if (foundP) setSelectedPlayer(foundP);
        }
        // Restauration de l'utilisateur
        if (pendingUserId && usersResp.data) {
          const foundU = usersResp.data.find((u) => u.id === pendingUserId);
          if (foundU) setSelectedUser(foundU);
        }
        // Restauration des tags
        if (pendingTagIds.length > 0 && tagsResp.data) {
          const foundTags = tagsResp.data.filter((tag) =>
            pendingTagIds.includes(tag.id)
          );
          if (foundTags.length > 0) setSelectedTags(foundTags);
        }
      } catch (err) {
        console.error('Error al cargar datos iniciales:', err);
      }
      // On rafraîchit ensuite la data
      refreshData();
    };
    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Re-fetch si on revient sur la page (focus)
  useEffect(() => {
    const handleFocus = () => {
      refreshData();
    };
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  // =========================================================
  // =============== HANDLERS CRUD ===========================
  // =========================================================
  const handleEditClick = (seguimientoId) => {
    navigate(`/seguimiento/edit/${seguimientoId}`);
  };

  const handleDelete = async (seguimientoId) => {
    try {
      await axios.delete(`${backendUrl}/api/seguimiento/${seguimientoId}`, {
        params: { section }
      });
      await refreshData();
      setOpenDeleteDialog(false);
      setSeguimientoToDelete(null);
    } catch (err) {
      console.error('Error al eliminar el Seguimiento:', err);
      setError('Error al eliminar el Seguimiento.');
    }
  };

  const handlePlayerClick = (player) => {
    navigate(`/players/${player.id}`);
  };

  const handlePlayerUrlClick = (url, event) => {
    event.stopPropagation();
    window.open(url, '_blank');
  };

  // =========================================================
  // =========== FILTRAGE SELON RÔLE, JOUEUR, TAGS, ETC. =====
  // =========================================================
  const filteredData = useMemo(() => {
    if (!data) return [];

    return data.filter((seguimiento) => {
      // 1) Filtrer par type selon userRole
      //    => on suppose que seguimiento.type = "Pro" ou "Cantera"
      const segTypeLower = seguimiento.type ? seguimiento.type.toLowerCase() : '';

      let passTypeCheck = true;
      if (userRole === 'super admin') {
        // Filtrer via seguimientoTypeFilter
        if (seguimientoTypeFilter === 'pro') {
          passTypeCheck = segTypeLower === 'pro';
        } else if (seguimientoTypeFilter === 'cantera') {
          passTypeCheck = segTypeLower === 'cantera';
        }
      } else if (userRole === 'admin pro') {
        passTypeCheck = segTypeLower === 'pro';
      } else if (userRole === 'admin cantera') {
        passTypeCheck = segTypeLower === 'cantera';
      } else if (userRole === 'entrenador' || userRole === 'pdp') {
        // On affiche uniquement ceux créés par l'utilisateur
        passTypeCheck = seguimiento.user.id === currentUserId;
      }

      if (!passTypeCheck) return false;

      // 2) Filtre sur selectedPlayer
      const matchesPlayer =
        !selectedPlayer ||
        seguimiento.players.some((p) => p.id === selectedPlayer.id);
      if (!matchesPlayer) return false;

      // 3) Filtre sur selectedUser
      const matchesUser =
        !selectedUser || seguimiento.user.id === selectedUser.id;
      if (!matchesUser) return false;

      // 4) Filtre sur tags sélectionnés
      const matchesTags =
        selectedTags.length === 0 ||
        seguimiento.tags.some((st) =>
          selectedTags.some((selTag) => selTag.id === st.id)
        );

      return matchesTags;
    });
  }, [
    data,
    selectedPlayer,
    selectedUser,
    selectedTags,
    userRole,
    currentUserId,
    seguimientoTypeFilter
  ]);

  // Regroupement par tag
  const groupedByTag = useMemo(() => {
    if (!tags) return [];
    const map = new Map();
    tags.forEach((t) => {
      map.set(t.id, { tag: t, seguimientos: [] });
    });

    filteredData.forEach((seg) => {
      seg.tags.forEach((t) => {
        if (map.has(t.id)) {
          map.get(t.id).seguimientos.push(seg);
        }
      });
    });
    // On ne garde que ceux qui ont des seguimientos
    return Array.from(map.values()).filter((g) => g.seguimientos.length > 0);
  }, [tags, filteredData]);

  // =========================================================
  // ========== MISE A JOUR DE L'URL (searchParams) ==========
  // =========================================================
  const updateSearchParams = (newState) => {
    const params = new URLSearchParams(searchParams.toString());

    // expanded
    if (newState.expandedTags) {
      params.set('expanded', newState.expandedTags.join(','));
    } else if (expandedTags.size > 0) {
      params.set('expanded', Array.from(expandedTags).join(','));
    } else {
      params.delete('expanded');
    }

    // selectedPlayer
    if (newState.selectedPlayer !== undefined) {
      const val = newState.selectedPlayer ? newState.selectedPlayer.id : '';
      val ? params.set('playerId', val) : params.delete('playerId');
    } else if (selectedPlayer) {
      params.set('playerId', selectedPlayer.id);
    } else {
      params.delete('playerId');
    }

    // selectedUser
    if (newState.selectedUser !== undefined) {
      const val = newState.selectedUser ? newState.selectedUser.id : '';
      val ? params.set('userId', val) : params.delete('userId');
    } else if (selectedUser) {
      params.set('userId', selectedUser.id);
    } else {
      params.delete('userId');
    }

    // selectedTags
    if (newState.selectedTags !== undefined) {
      const tIds = newState.selectedTags.map((tg) => tg.id);
      if (tIds.length > 0) {
        params.set('tagIds', tIds.join(','));
      } else {
        params.delete('tagIds');
      }
    } else if (selectedTags.length > 0) {
      params.set(
        'tagIds',
        selectedTags.map((tg) => tg.id).join(',')
      );
    } else {
      params.delete('tagIds');
    }

    setSearchParams(params, { replace: true });
  };

  // A chaque changement de selectedPlayer / selectedUser / selectedTags / expandedTags
  useEffect(() => {
    updateSearchParams({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayer, selectedUser, selectedTags, expandedTags]);

  // Pour désactiver le lien joueur si role = entrenador ou pdp
  const disablePlayerLink = userRole === 'entrenador' || userRole === 'pdp';

  // =========================================================
  // ============ GÉNÉRATION PDF POUR UN TAG =================
  // =========================================================
  const generatePDFForTag = async (tag, seguimientos) => {
    try {
      // Préparer la date (jj-mm-aaaa)
      const now = new Date();
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const year = now.getFullYear();
      const dateStr = `${day}-${month}-${year}`;

      // Création du document
      const doc = new jsPDF();

      // Page template (cadre + bande orange bas + logos)
      addPageTemplate(doc);

      // Titre principal : "VBC SEGUIMIENTO - {Tag} - {Date}"
      let yPos = 20;
      doc.setFontSize(18);
      doc.setTextColor(0);
      doc.text(
        `VBC SEGUIMIENTO - ${tag.libelle} - ${dateStr}`,
        doc.internal.pageSize.width / 2,
        yPos,
        { align: 'center' }
      );
      yPos += 15;

      for (let sIndex = 0; sIndex < seguimientos.length; sIndex++) {
        const seguimiento = seguimientos[sIndex];

        // Vérifier l'espace restant sur la page
        if (yPos > doc.internal.pageSize.height - 50) {
          doc.addPage();
          addPageTemplate(doc);
          yPos = 20;
        }

        // Titre du seguimiento
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(0);
        doc.text(seguimiento.libelle, 20, yPos);
        yPos += 8;

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(9);
        doc.setTextColor(0);

        // Préparer les données pour le tableau
        const playersSorted = seguimiento.players.sort((a, b) =>
          a.nom.localeCompare(b.nom)
        );

        // On construit le tableau "Nombre" + "Infos"
        // "Infos" => "poste - annee_naissance - taille - nationalite(passport) - team - leagues"
        const rowDataArray = playersSorted.map((p) => {
          const parts = [];
          if (p.poste) parts.push(p.poste);
          if (p.annee_naissance) parts.push(p.annee_naissance);
          if (p.taille) parts.push(`${p.taille}cm`);

          if (p.nationalite) {
            const natPassport = p.passport
              ? `${p.nationalite} (${p.passport})`
              : p.nationalite;
            parts.push(natPassport);
          }

          if (p.team) parts.push(p.team);

          const leagues = [p.league_1, p.league_2, p.competicion_europa].filter(Boolean);
          if (leagues.length > 0) {
            parts.push(leagues.join(' - '));
          }

          const infoLine = parts.join(' - ');
          return [p.nom, infoLine];
        });

        // autoTable avec thème 'grid'
        doc.autoTable({
          startY: yPos,
          head: [['Nombre', 'Infos']],
          body: rowDataArray,
          theme: 'grid',
          headStyles: {
            fillColor: [234, 127, 0], // orange
            textColor: [255, 255, 255], // texte en blanc
            fontSize: 9,
            fontStyle: 'bold'
          },
          styles: {
            fontSize: 8,
            cellPadding: 2, // moins d'espace
            overflow: 'linebreak'
          },
          margin: { left: 15, right: 15, bottom: 30 },
          didDrawPage: () => {
            addPageTemplate(doc);
          }
        });

        yPos = doc.lastAutoTable.finalY + 10;
      }

      // Téléchargement du PDF
      doc.save(`VBC_SEGUIMIENTO_${tag.libelle}_${dateStr}.pdf`);
    } catch (err) {
      console.error('Erreur génération PDF:', err);
    }
  };

  // =========================================================
  // =============== RENDER ==================================
  // =========================================================
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }
  if (!data || data.length === 0) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        No hay Seguimientos disponibles
      </Alert>
    );
  }

  return (
    <Paper elevation={2}>
      <Box sx={{ p: 2, pb: isMobile ? 7 : 2 }}>
        {/* Si non mobile, affichage direct des filtres */}
        {!isMobile && (
          <Grid container spacing={2} sx={{ mb: 3 }}>
            {/* Filtre Joueur */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                fullWidth
                options={players.players || []}
                getOptionLabel={(player) => player?.nom || ''}
                value={selectedPlayer}
                onChange={(_, newValue) => {
                  setSelectedPlayer(newValue);
                  updateSearchParams({ selectedPlayer: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Filtrar por Jugador" />
                )}
                renderOption={(props, p) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    <Avatar
                      src={`${mediaUrl}/photos/${p.id}.png`}
                      alt={p.nom}
                      sx={{ width: 32, height: 32 }}
                    />
                    <Typography noWrap>{p.nom}</Typography>
                  </Box>
                )}
                ListboxComponent={ListboxComponent}
                clearOnEscape
              />
            </Grid>

            {/* Filtre User */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                fullWidth
                options={users}
                getOptionLabel={(u) => (u ? `${u.prenom} ${u.nom}` : '')}
                value={selectedUser}
                onChange={(_, newValue) => {
                  setSelectedUser(newValue);
                  updateSearchParams({ selectedUser: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Filtrar por Usuario" />
                )}
                renderOption={(props, u) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    <UserAvatar user={u} mediaUrl={mediaUrl} size={32} />
                    <Typography noWrap>{`${u.prenom} ${u.nom}`}</Typography>
                  </Box>
                )}
                clearOnEscape
              />
            </Grid>

            {/* Si superadmin => select Pro/Cantera + Autocomplete tags */}
            {userRole === 'super admin' ? (
              <>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    multiple
                    fullWidth
                    options={tags}
                    getOptionLabel={(t) => t.libelle}
                    value={selectedTags}
                    onChange={(_, newValue) => {
                      setSelectedTags(newValue);
                      updateSearchParams({ selectedTags: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Filtrar por Tags" />
                    )}
                    renderOption={(props, t) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Chip
                          size="small"
                          label={t.libelle}
                          style={{
                            backgroundColor: t.code_couleur,
                            color: getContrastColor(t.code_couleur)
                          }}
                        />
                      </Box>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((tg, index) => (
                        <Chip
                          key={tg.id}
                          size="small"
                          label={tg.libelle}
                          {...getTagProps({ index })}
                          style={{
                            backgroundColor: tg.code_couleur,
                            color: getContrastColor(tg.code_couleur)
                          }}
                        />
                      ))
                    }
                    clearOnEscape
                  />
                </Grid>

                <Grid item xs={12} md={1}>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      value={seguimientoTypeFilter}
                      onChange={(e) => setSeguimientoTypeFilter(e.target.value)}
                    >
                      <MenuItem value="all">Todos</MenuItem>
                      <MenuItem value="pro">Pro</MenuItem>
                      <MenuItem value="cantera">Cantera</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : (
              // Sinon, juste le filtre tags
              <Grid item xs={12} md={4}>
                <Autocomplete
                  multiple
                  fullWidth
                  options={tags}
                  getOptionLabel={(t) => t.libelle}
                  value={selectedTags}
                  onChange={(_, newValue) => {
                    setSelectedTags(newValue);
                    updateSearchParams({ selectedTags: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Filtrar por Tags" />
                  )}
                  renderOption={(props, t) => (
                    <Box
                      component="li"
                      {...props}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <Chip
                        size="small"
                        label={t.libelle}
                        style={{
                          backgroundColor: t.code_couleur,
                          color: getContrastColor(t.code_couleur)
                        }}
                      />
                    </Box>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((tg, index) => (
                      <Chip
                        key={tg.id}
                        size="small"
                        label={tg.libelle}
                        {...getTagProps({ index })}
                        style={{
                          backgroundColor: tg.code_couleur,
                          color: getContrastColor(tg.code_couleur)
                        }}
                      />
                    ))
                  }
                  clearOnEscape
                />
              </Grid>
            )}
          </Grid>
        )}

        {/* Nombre de tags (groupedByTag) */}
        <Box
          sx={{
            mx: 2,
            mt: 2,
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            p: 2,
            bgcolor: theme.palette.primary.main,
            color: 'white',
            borderRadius: 1,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          <Box
            sx={{
              bgcolor: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '50%',
              width: 40,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mr: 2,
              fontWeight: 'bold',
              fontSize: '1.1rem'
            }}
          >
            {groupedByTag.length}
          </Box>
          <Typography variant="subtitle1">
            Seguimientos Encontrados
          </Typography>
        </Box>

        {/* Sections regroupées par Tag */}
        {groupedByTag.map(({ tag, seguimientos }) => (
          <SeguimientoSection
            key={tag.id}
            tag={tag}
            seguimientos={seguimientos}
            onEdit={handleEditClick}
            onDelete={(s) => {
              setSeguimientoToDelete(s);
              setOpenDeleteDialog(true);
            }}
            onPlayerClick={handlePlayerClick}
            onPlayerUrlClick={handlePlayerUrlClick}
            mediaUrl={mediaUrl}
            expandedTags={expandedTags}
            setExpandedTags={setExpandedTags}
            updateSearchParams={updateSearchParams}
            disablePlayerLink={disablePlayerLink}
            onGeneratePDFForTag={generatePDFForTag}
          />
        ))}

        {/* BOUTON FLOTTANT MOBILE */}
        {isMobile && (
          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.fab
            }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            <FilterList />
          </Fab>
        )}
      </Box>

      {/* DRAWER MOBILE POUR LES FILTRES */}
      <FilterDrawer
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
        players={players}
        users={users}
        selectedPlayer={selectedPlayer}
        selectedUser={selectedUser}
        onPlayerChange={(_, newValue) => {
          setSelectedPlayer(newValue);
          updateSearchParams({ selectedPlayer: newValue });
        }}
        onUserChange={(_, newValue) => {
          setSelectedUser(newValue);
          updateSearchParams({ selectedUser: newValue });
        }}
        mediaUrl={mediaUrl}
        tags={tags}
        selectedTags={selectedTags}
        onTagsChange={(_, newValue) => {
          setSelectedTags(newValue);
          updateSearchParams({ selectedTags: newValue });
        }}
        userRole={userRole}
        seguimientoTypeFilter={seguimientoTypeFilter}
        setSeguimientoTypeFilter={setSeguimientoTypeFilter}
      />

      {/* DIALOG DE SUPPRESSION */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setSeguimientoToDelete(null);
        }}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro de que desea eliminar el Seguimiento "
            {seguimientoToDelete?.libelle}"? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              setSeguimientoToDelete(null);
            }}
          >
            Cancelar
          </Button>
          <Button
            color="error"
            onClick={() => handleDelete(seguimientoToDelete?.id)}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default SeguimientoTable;
