// components/CaracteristicasJugador.js

import React from 'react';
import {
  Paper,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  ListItemText
} from '@mui/material';
import { Clear } from '@mui/icons-material';

// === Arrays de roles y atributos (puedes ajustarlos o importarlos de otro lugar) ===
const offensiveRoles = [
  // Guard
  'Generador con balón',
  'Generador secundario con balón',
  'Anotador Dominante',
  'Base Director',
  'Tirador Especialista',

  // Wing
  'Alero Generador',
  'Ala Versátil',
  'Alero Fuerte',
  'Alero Slasher',
  'Alero Spot-up',
  '3&D',

  // Forward
  'Ala-Pívot Tradicional',
  'Skilled Forward',
  '4 Abierto',

  // Big
  'Pívot Generador',
  'Pívot Versátil',
  'Pívot Mid-Range',
  '5 Abierto',
  'Anotador en el poste bajo',
  'Pívot finalizador cerca del aro',
  'Pívot de bloqueo y continuación',
  'Rol Ofensivo Marginal'
];

const defensiveRoles = [
  '1x1 Intenso',
  'Defensor Perimetral',
  'Wing Stopper',
  'Switch Defensor',
  'Anchor Big',
  'Reboteador Defensivo',
  'Rim Protector',
  'No Defensor'
];

const JEAttributes = [
  '1x1',
  '3-Level Scorer',
  'Actividad sin balón',
  'Atlético',
  'Capacidad en el Pick & Roll',
  'Capacidad para sacar faltas',
  'Clutch',
  'Duro',
  'Energía',
  'Envergadura',
  'Físico',
  'Hustle',
  'IQ/Visión de juego',
  'Juego en el poste bajo',
  'Paint Touches',
  'Penetrador',
  'Puntos',
  'Primer paso',
  'Producción constante',
  'Rebote',
  'Talento',
  'Tirador',
  'Tiro en Catch & Shoot (OTC 3)',
  'Tiro desde la esquina (Corner 3)',
  'Tiro tras Bote',
  'Transiciones/Ritmo en la pista',
  'Trabajo sucio (Blue Collar)',
  'Velocidad/Agilidad',
  'Versatilidad',
  'Verticalidad'
];

const JIAttributes = [
  '1x1',
  'Actividad sin balón',
  'Atlético',
  'Bloqueo, continuación y finalización',
  'Bloqueos eficaces',
  'Buen tacto',
  'Clutch',
  'Duro',
  'Energía',
  'Envergadura',
  'Footwork',
  'Físico',
  'Hustle',
  'IQ/Visión de juego',
  'Juego poste bajo/Juego de cara',
  'Lob Threat',
  'Puntos',
  'Producción constante',
  'Protector del aro',
  'Rebote',
  'Rim-Runner',
  'Talento',
  'Tirador',
  'Tiro en Catch & Shoot (OTC 3)',
  'Tiro desde la esquina + P&Pop',
  'Tiro tras bote',
  'Trabajo sucio (Blue Collar)',
  'Transiciones/Ritmo en la pista',
  'Varios esfuerzos en la misma acción',
  'Velocidad/Agilidad',
  'Versatilidad',
  'Verticalidad'
];

// Opciones para el rating actual / proyección (si deseas, puedes extraerlo en un array aparte)
const vbcRatings = [
  'Proyecto Nivel Bajo',
  'Proyecto Nivel Medio',
  'Proyecto Nivel Bueno',
  'Proyecto Top',
  'Futura Estrella',
  'EBA Nivel Bajo (10-20)',
  'EBA Nivel Alto (20-30)',
  'LEB Nivel Bajo (30-40)',
  'LEB Nivel Alto (40-50)',
  'ACB Rotación (50-60)',
  'Bottom BCL/Eurocup (60-65)',
  'Mid BCL/Eurocup (65-70)',
  'Top BCL/Eurocup (70-75)',
  'EL Nivel Medio (75-80)',
  'EL Top (80-85)',
  'NBA Rotación (85-90)',
  'NBA All-Star (95-100)',
  'College Nivel Bajo',
  'College Nivel Medio',
  'College Top'
];

function CaracteristicasJugador({
  player,
  editablePlayer,
  setEditablePlayer,
  isEditing,
  isMobile,
  handleChange
}) {
  // Función para limpiar arrays al hacer click en "x"
  const clearSelectValue = (e, fieldName) => {
    e.stopPropagation();
    setEditablePlayer((prev) => ({
      ...prev,
      [fieldName]: ''
    }));
  };

  // === Render para MOBILE ===
  if (isMobile) {
    return (
      <Box
        sx={{
          mb: 2,
          backgroundColor: 'background.default'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'medium',
            px: 2,
            py: 1,
            backgroundColor: 'background.paper'
          }}
        >
          2. Características del Jugador
        </Typography>

        <Box sx={{ p: 2 }}>
          {isEditing ? (
            <Grid container spacing={2}>
              {/* Posición */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Posición</InputLabel>
                  <Select
                    name="poste"
                    value={editablePlayer.poste || ''}
                    onChange={handleChange}
                    label="Posición"
                    endAdornment={
                      editablePlayer.poste && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'poste')}
                          sx={{
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="Base">Base</MenuItem>
                    <MenuItem value="Escolta">Escolta</MenuItem>
                    <MenuItem value="Alero">Alero</MenuItem>
                    <MenuItem value="Ala-Pívot">Ala-Pívot</MenuItem>
                    <MenuItem value="Pívot">Pívot</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Mano Dominante */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Mano Dominante</InputLabel>
                  <Select
                    name="mano"
                    value={editablePlayer.mano || ''}
                    onChange={handleChange}
                    label="Mano Dominante"
                    endAdornment={
                      editablePlayer.mano && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'mano')}
                          sx={{
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="Derecha">Derecha</MenuItem>
                    <MenuItem value="Izquierda">Izquierda</MenuItem>
                    <MenuItem value="Ambas">Ambas</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Shooting Range */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Shooting Range</InputLabel>
                  <Select
                    name="shootingRange"
                    value={editablePlayer.shootingRange || ''}
                    onChange={handleChange}
                    label="Shooting Range"
                    endAdornment={
                      editablePlayer.shootingRange && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'shootingRange')}
                          sx={{
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="Rim">Rim</MenuItem>
                    <MenuItem value="Mid-Range">Mid-Range</MenuItem>
                    <MenuItem value="3Pts">3Pts</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Perfil Ofensivo */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Perfil Ofensivo</InputLabel>
                  <Select
                    multiple
                    name="rolOfensivo"
                    // Si está guardado con comas, convertimos a array
                    value={
                      editablePlayer.rolOfensivo
                        ? editablePlayer.rolOfensivo.split(',')
                        : []
                    }
                    onChange={(e) => {
                      // Convertimos array a string separado por comas
                      setEditablePlayer((prev) => ({
                        ...prev,
                        rolOfensivo: e.target.value.join(',')
                      }));
                    }}
                    renderValue={(selected) => selected.join(', ')}
                    endAdornment={
                      editablePlayer.rolOfensivo && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditablePlayer((prev) => ({
                              ...prev,
                              rolOfensivo: ''
                            }));
                          }}
                          sx={{
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    {/* GUARD */}
                    <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
                      Guard
                    </MenuItem>
                    {offensiveRoles.slice(0, 5).map((role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox
                          checked={
                            editablePlayer.rolOfensivo &&
                            editablePlayer.rolOfensivo.split(',').includes(role)
                          }
                        />
                        <ListItemText primary={role} />
                      </MenuItem>
                    ))}

                    {/* WING */}
                    <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
                      Wing
                    </MenuItem>
                    {offensiveRoles.slice(5, 11).map((role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox
                          checked={
                            editablePlayer.rolOfensivo &&
                            editablePlayer.rolOfensivo.split(',').includes(role)
                          }
                        />
                        <ListItemText primary={role} />
                      </MenuItem>
                    ))}

                    {/* FORWARD */}
                    <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
                      Forward
                    </MenuItem>
                    {offensiveRoles.slice(11, 14).map((role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox
                          checked={
                            editablePlayer.rolOfensivo &&
                            editablePlayer.rolOfensivo.split(',').includes(role)
                          }
                        />
                        <ListItemText primary={role} />
                      </MenuItem>
                    ))}

                    {/* BIG */}
                    <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
                      Big
                    </MenuItem>
                    {offensiveRoles.slice(14).map((role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox
                          checked={
                            editablePlayer.rolOfensivo &&
                            editablePlayer.rolOfensivo.split(',').includes(role)
                          }
                        />
                        <ListItemText primary={role} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Perfil Defensivo */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Perfil Defensivo</InputLabel>
                  <Select
                    multiple
                    name="rolDefensivo"
                    value={
                      editablePlayer.rolDefensivo
                        ? editablePlayer.rolDefensivo.split(',')
                        : []
                    }
                    onChange={(e) => {
                      setEditablePlayer((prev) => ({
                        ...prev,
                        rolDefensivo: e.target.value.join(',')
                      }));
                    }}
                    renderValue={(selected) => selected.join(', ')}
                    endAdornment={
                      editablePlayer.rolDefensivo && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditablePlayer((prev) => ({
                              ...prev,
                              rolDefensivo: ''
                            }));
                          }}
                          sx={{
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    {defensiveRoles.map((role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox
                          checked={
                            editablePlayer.rolDefensivo &&
                            editablePlayer.rolDefensivo.split(',').includes(role)
                          }
                        />
                        <ListItemText primary={role} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* JE/JI */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Skills JE/JI</InputLabel>
                  <Select
                    name="jeJiType"
                    value={editablePlayer.jeJiType || ''}
                    onChange={(e) => {
                      // si cambia el tipo, reseteamos los atributos
                      setEditablePlayer((prev) => ({
                        ...prev,
                        jeJiType: e.target.value,
                        jeJiAttributes: []
                      }));
                    }}
                    label="Skills JE/JI"
                    endAdornment={
                      editablePlayer.jeJiType && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditablePlayer((prev) => ({
                              ...prev,
                              jeJiType: '',
                              jeJiAttributes: []
                            }));
                          }}
                          sx={{
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="JE">JE (Juego Exterior)</MenuItem>
                    <MenuItem value="JI">JI (Juego Interior)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {editablePlayer.jeJiType && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>
                      Skills{editablePlayer.jeJiType === 'JE' ? 'Exterior' : 'Interior'}
                    </InputLabel>
                    <Select
                      multiple
                      value={editablePlayer.jeJiAttributes || []}
                      onChange={(e) =>
                        setEditablePlayer((prev) => ({
                          ...prev,
                          jeJiAttributes: e.target.value
                        }))
                      }
                      renderValue={(selected) => selected.join(', ')}
                      endAdornment={
                        editablePlayer.jeJiAttributes?.length > 0 && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                jeJiAttributes: []
                              }));
                            }}
                            sx={{
                              position: 'absolute',
                              right: 32
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )
                      }
                    >
                      {(editablePlayer.jeJiType === 'JE'
                        ? JEAttributes
                        : JIAttributes
                      ).map((attr) => (
                        <MenuItem key={attr} value={attr}>
                          <Checkbox
                            checked={editablePlayer.jeJiAttributes.includes(attr)}
                          />
                          <ListItemText primary={attr} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {/* Current VBC Rating, Proyección */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Current VBC Rating</InputLabel>
                  <Select
                    name="nivel"
                    value={editablePlayer.nivel || ''}
                    onChange={handleChange}
                    label="Current VBC Rating"
                    endAdornment={
                      editablePlayer.nivel && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'nivel')}
                          sx={{
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    {vbcRatings.map((rating) => (
                      <MenuItem key={rating} value={rating}>
                        {rating}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Proyección VBC Rating</InputLabel>
                  <Select
                    name="proyeccion"
                    value={editablePlayer.proyeccion || ''}
                    onChange={handleChange}
                    label="Proyección VBC Rating"
                    endAdornment={
                      editablePlayer.proyeccion && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'proyeccion')}
                          sx={{
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    {vbcRatings.map((rating) => (
                      <MenuItem key={rating} value={rating}>
                        {rating}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Flag Behaviour, Injury Prone */}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Flag Behaviour</InputLabel>
                  <Select
                    name="comportamiento"
                    value={editablePlayer.comportamiento || ''}
                    onChange={handleChange}
                    label="Flag Behaviour"
                    endAdornment={
                      editablePlayer.comportamiento && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'comportamiento')}
                          sx={{
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="Green">Green</MenuItem>
                    <MenuItem value="Orange">Orange</MenuItem>
                    <MenuItem value="Red">Red</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Injury Prone</InputLabel>
                  <Select
                    name="injury"
                    value={editablePlayer.injury || ''}
                    onChange={handleChange}
                    label="Injury Prone"
                    endAdornment={
                      editablePlayer.injury && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'injury')}
                          sx={{
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="Green">Green</MenuItem>
                    <MenuItem value="Orange">Orange</MenuItem>
                    <MenuItem value="Red">Red</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Comparación Pro */}
              <Grid item xs={12}>
                <TextField
                  label="Comparación con un Jugador Pro"
                  name="comparacionPro"
                  value={editablePlayer.comparacionPro || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          ) : (
            // READ ONLY (MOBILE)
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {!!player.poste && (
                <Typography>
                  <strong>Posición:</strong> {player.poste}
                </Typography>
              )}
              {!!player.mano && (
                <Typography>
                  <strong>Mano Dominante:</strong> {player.mano}
                </Typography>
              )}
              {!!player.shootingRange && (
                <Typography>
                  <strong>Shooting Range:</strong> {player.shootingRange}
                </Typography>
              )}
              {!!player.rolOfensivo && (
                <Typography>
                  <strong>Perfil Ofensivo:</strong> {player.rolOfensivo}
                </Typography>
              )}
              {!!player.rolDefensivo && (
                <Typography>
                  <strong>Perfil Defensivo:</strong> {player.rolDefensivo}
                </Typography>
              )}
              {!!player.jeJiType && (
                <Typography>
                  <strong>Skills JE/JI:</strong> {player.jeJiType}
                </Typography>
              )}
              {!!player.jeJiAttributes?.length && (
                <Typography>
                  <strong>Skills&nbsp;({player.jeJiType}):</strong>{' '}
                  {player.jeJiAttributes.join(', ')}
                </Typography>
              )}
              {!!player.nivel && (
                <Typography>
                  <strong>Current VBC Rating:</strong> {player.nivel}
                </Typography>
              )}
              {!!player.proyeccion && (
                <Typography>
                  <strong>Proyección VBC Rating:</strong> {player.proyeccion}
                </Typography>
              )}
              {!!player.comportamiento && (
                <Typography>
                  <strong>Flag Behaviour:</strong> {player.comportamiento}
                </Typography>
              )}
              {!!player.injury && (
                <Typography>
                  <strong>Injury Prone:</strong> {player.injury}
                </Typography>
              )}
              {!!player.comparacionPro && (
                <Typography>
                  <strong>Comparación con un Jugador Pro:</strong>{' '}
                  {player.comparacionPro}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  // === Render para DESKTOP ===
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        2. Características del Jugador
      </Typography>

      {isEditing ? (
        <Grid container spacing={2}>
          {/* Posición */}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Posición</InputLabel>
              <Select
                name="poste"
                value={editablePlayer.poste || ''}
                onChange={handleChange}
                label="Posición"
                endAdornment={
                  editablePlayer.poste && (
                    <IconButton
                      onClick={(e) => clearSelectValue(e, 'poste')}
                      sx={{
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                <MenuItem value="Base">Base</MenuItem>
                <MenuItem value="Escolta">Escolta</MenuItem>
                <MenuItem value="Alero">Alero</MenuItem>
                <MenuItem value="Ala-Pívot">Ala-Pívot</MenuItem>
                <MenuItem value="Pívot">Pívot</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Mano Dominante */}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Mano Dominante</InputLabel>
              <Select
                name="mano"
                value={editablePlayer.mano || ''}
                onChange={handleChange}
                label="Mano Dominante"
                endAdornment={
                  editablePlayer.mano && (
                    <IconButton
                      onClick={(e) => clearSelectValue(e, 'mano')}
                      sx={{
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                <MenuItem value="Derecha">Derecha</MenuItem>
                <MenuItem value="Izquierda">Izquierda</MenuItem>
                <MenuItem value="Ambas">Ambas</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Shooting Range */}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Shooting Range</InputLabel>
              <Select
                name="shootingRange"
                value={editablePlayer.shootingRange || ''}
                onChange={handleChange}
                label="Shooting Range"
                endAdornment={
                  editablePlayer.shootingRange && (
                    <IconButton
                      onClick={(e) => clearSelectValue(e, 'shootingRange')}
                      sx={{
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                <MenuItem value="Rim">Rim</MenuItem>
                <MenuItem value="Mid-Range">Mid-Range</MenuItem>
                <MenuItem value="3Pts">3Pts</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Perfil Ofensivo */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Perfil Ofensivo</InputLabel>
              <Select
                multiple
                name="rolOfensivo"
                value={
                  editablePlayer.rolOfensivo
                    ? editablePlayer.rolOfensivo.split(',')
                    : []
                }
                onChange={(e) => {
                  setEditablePlayer((prev) => ({
                    ...prev,
                    rolOfensivo: e.target.value.join(',')
                  }));
                }}
                renderValue={(selected) => selected.join(', ')}
                endAdornment={
                  editablePlayer.rolOfensivo && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          rolOfensivo: ''
                        }));
                      }}
                      sx={{
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                {/* GUARD */}
                <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
                  Guard
                </MenuItem>
                {offensiveRoles.slice(0, 5).map((role) => (
                  <MenuItem key={role} value={role}>
                    <Checkbox
                      checked={
                        editablePlayer.rolOfensivo &&
                        editablePlayer.rolOfensivo.split(',').includes(role)
                      }
                    />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}

                {/* WING */}
                <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
                  Wing
                </MenuItem>
                {offensiveRoles.slice(5, 11).map((role) => (
                  <MenuItem key={role} value={role}>
                    <Checkbox
                      checked={
                        editablePlayer.rolOfensivo &&
                        editablePlayer.rolOfensivo.split(',').includes(role)
                      }
                    />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}

                {/* FORWARD */}
                <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
                  Forward
                </MenuItem>
                {offensiveRoles.slice(11, 14).map((role) => (
                  <MenuItem key={role} value={role}>
                    <Checkbox
                      checked={
                        editablePlayer.rolOfensivo &&
                        editablePlayer.rolOfensivo.split(',').includes(role)
                      }
                    />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}

                {/* BIG */}
                <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
                  Big
                </MenuItem>
                {offensiveRoles.slice(14).map((role) => (
                  <MenuItem key={role} value={role}>
                    <Checkbox
                      checked={
                        editablePlayer.rolOfensivo &&
                        editablePlayer.rolOfensivo.split(',').includes(role)
                      }
                    />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Perfil Defensivo */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Perfil Defensivo</InputLabel>
              <Select
                multiple
                name="rolDefensivo"
                value={
                  editablePlayer.rolDefensivo
                    ? editablePlayer.rolDefensivo.split(',')
                    : []
                }
                onChange={(e) => {
                  setEditablePlayer((prev) => ({
                    ...prev,
                    rolDefensivo: e.target.value.join(',')
                  }));
                }}
                renderValue={(selected) => selected.join(', ')}
                endAdornment={
                  editablePlayer.rolDefensivo && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          rolDefensivo: ''
                        }));
                      }}
                      sx={{
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                {defensiveRoles.map((role) => (
                  <MenuItem key={role} value={role}>
                    <Checkbox
                      checked={
                        editablePlayer.rolDefensivo &&
                        editablePlayer.rolDefensivo.split(',').includes(role)
                      }
                    />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* JE / JI */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Skills JE/JI</InputLabel>
              <Select
                name="jeJiType"
                value={editablePlayer.jeJiType || ''}
                onChange={(e) => {
                  setEditablePlayer((prev) => ({
                    ...prev,
                    jeJiType: e.target.value,
                    jeJiAttributes: []
                  }));
                }}
                label="Skills JE/JI"
                endAdornment={
                  editablePlayer.jeJiType && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          jeJiType: '',
                          jeJiAttributes: []
                        }));
                      }}
                      sx={{
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                <MenuItem value="JE">JE (Juego Exterior)</MenuItem>
                <MenuItem value="JI">JI (Juego Interior)</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {editablePlayer.jeJiType && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>
                  Skills {editablePlayer.jeJiType === 'JE' ? 'Exterior' : 'Interior'}
                </InputLabel>
                <Select
                  multiple
                  value={editablePlayer.jeJiAttributes || []}
                  onChange={(e) =>
                    setEditablePlayer((prev) => ({
                      ...prev,
                      jeJiAttributes: e.target.value
                    }))
                  }
                  renderValue={(selected) => selected.join(', ')}
                  endAdornment={
                    editablePlayer.jeJiAttributes?.length > 0 && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            jeJiAttributes: []
                          }));
                        }}
                        sx={{
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    )
                  }
                >
                  {(editablePlayer.jeJiType === 'JE' ? JEAttributes : JIAttributes).map(
                    (attr) => (
                      <MenuItem key={attr} value={attr}>
                        <Checkbox
                          checked={editablePlayer.jeJiAttributes.includes(attr)}
                        />
                        <ListItemText primary={attr} />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
          )}

          {/* Current VBC Rating */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Current VBC Rating</InputLabel>
              <Select
                name="nivel"
                value={editablePlayer.nivel || ''}
                onChange={handleChange}
                label="Current VBC Rating"
                endAdornment={
                  editablePlayer.nivel && (
                    <IconButton
                      onClick={(e) => clearSelectValue(e, 'nivel')}
                      sx={{ position: 'absolute', right: 32 }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                {vbcRatings.map((rating) => (
                  <MenuItem key={rating} value={rating}>
                    {rating}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Proyección VBC Rating */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Proyección VBC Rating</InputLabel>
              <Select
                name="proyeccion"
                value={editablePlayer.proyeccion || ''}
                onChange={handleChange}
                label="Proyección VBC Rating"
                endAdornment={
                  editablePlayer.proyeccion && (
                    <IconButton
                      onClick={(e) => clearSelectValue(e, 'proyeccion')}
                      sx={{ position: 'absolute', right: 32 }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                {vbcRatings.map((rating) => (
                  <MenuItem key={rating} value={rating}>
                    {rating}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Flag Behaviour e Injury Prone */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Flag Behaviour</InputLabel>
              <Select
                name="comportamiento"
                value={editablePlayer.comportamiento || ''}
                onChange={handleChange}
                label="Flag Behaviour"
                endAdornment={
                  editablePlayer.comportamiento && (
                    <IconButton
                      onClick={(e) => clearSelectValue(e, 'comportamiento')}
                      sx={{
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Orange">Orange</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Injury Prone</InputLabel>
              <Select
                name="injury"
                value={editablePlayer.injury || ''}
                onChange={handleChange}
                label="Injury Prone"
                endAdornment={
                  editablePlayer.injury && (
                    <IconButton
                      onClick={(e) => clearSelectValue(e, 'injury')}
                      sx={{
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Orange">Orange</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Comparación Pro */}
          <Grid item xs={12}>
            <TextField
              label="Comparación con un Jugador Pro"
              name="comparacionPro"
              value={editablePlayer.comparacionPro || ''}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
      ) : (
        // READ ONLY (DESKTOP)
        <Box sx={{ mt: 1 }}>
          {!!player.poste && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>Posición:&nbsp;</strong>
              {player.poste}
            </Typography>
          )}
          {!!player.mano && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>Mano Dominante:&nbsp;</strong>
              {player.mano}
            </Typography>
          )}
          {!!player.shootingRange && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>Shooting Range:&nbsp;</strong>
              {player.shootingRange}
            </Typography>
          )}

          {(!!player.rolOfensivo || !!player.rolDefensivo) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.rolOfensivo && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Perfil Ofensivo:&nbsp;</strong>
                  {player.rolOfensivo}
                </Typography>
              )}
              {!!player.rolDefensivo && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Perfil Defensivo:&nbsp;</strong>
                  {player.rolDefensivo}
                </Typography>
              )}
            </Box>
          )}

          {(!!player.jeJiType || !!player.jeJiAttributes?.length) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.jeJiType && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Skills JE/JI:&nbsp;</strong>
                  {player.jeJiType}
                </Typography>
              )}
              {!!player.jeJiAttributes?.length && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>
                    Skills&nbsp;({player.jeJiType}):&nbsp;
                  </strong>
                  {player.jeJiAttributes.join(', ')}
                </Typography>
              )}
            </Box>
          )}

          {(!!player.nivel || !!player.proyeccion) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.nivel && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>
                    Current VBC Rating:&nbsp;
                  </strong>
                  {player.nivel}
                </Typography>
              )}
              {!!player.proyeccion && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>
                    Proyección VBC Rating:&nbsp;
                  </strong>
                  {player.proyeccion}
                </Typography>
              )}
            </Box>
          )}

          {(!!player.comportamiento || !!player.injury) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.comportamiento && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Flag Behaviour:&nbsp;</strong>
                  {player.comportamiento}
                </Typography>
              )}
              {!!player.injury && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Injury Prone:&nbsp;</strong>
                  {player.injury}
                </Typography>
              )}
            </Box>
          )}

          {!!player.comparacionPro && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>
                Comparación con un Jugador Pro:&nbsp;
              </strong>
              {player.comparacionPro}
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
}

export default CaracteristicasJugador;
