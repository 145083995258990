// components/InformacionAdicional.js

import React from 'react';
import {
  Paper,
  Typography,
  Box,
  Grid,
  TextField
} from '@mui/material';

/**
 * Este componente muestra/edita:
 * - URL Estadística
 */

function InformacionAdicional({
  player,
  editablePlayer,
  setEditablePlayer,
  isEditing,
  isMobile,
  handleChange
}) {
  // === Render para MOBILE ===
  if (isMobile) {
    return (
      <Box
        sx={{
          mb: 2,
          backgroundColor: 'background.default'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'medium',
            px: 2,
            py: 1,
            backgroundColor: 'background.paper'
          }}
        >
          6. Información Adicional
        </Typography>

        <Box sx={{ p: 2 }}>
          {isEditing ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="URL Estadística"
                  name="url"
                  value={editablePlayer.url || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          ) : (
            // READ ONLY (MOBILE)
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {!!player.url && (
                <Typography>
                  <strong>URL Estadística:</strong> {player.url}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  // === Render para DESKTOP ===
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        6. Información Adicional
      </Typography>

      {isEditing ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="URL Estadística"
              name="url"
              value={editablePlayer.url || ''}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
      ) : (
        // READ ONLY (DESKTOP)
        <Box sx={{ mt: 1 }}>
          {!!player.url && (
            <Typography>
              <strong>URL Estadística:</strong> {player.url}
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
}

export default InformacionAdicional;
