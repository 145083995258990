import React from 'react';
import {
  Paper,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  ListItemText
} from '@mui/material';
import { Clear } from '@mui/icons-material';


// IMPORTS custom
import NationalitiesSelect from '../NationalitiesSelect';
import NationalitiesSelect2 from '../NationalitiesSelect2';
import SelectSelectionNational from '../SelectSelectionNational';


// Funciones de formateo de fecha (si las necesitas aquí)
const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
};

const formatDateForDisplay = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function InformacionBasica({
  player,
  editablePlayer,
  setEditablePlayer,
  isEditing,
  isMobile,
  allGroups,
  handleChange,
  handleGroupChange
}) {
  // Render MOBILE
  if (isMobile) {
    return (
      <Box 
        sx={{ 
          mb: 2,
          backgroundColor: 'background.default'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'medium',
            px: 2,
            py: 1,
            backgroundColor: 'background.paper'
          }}
        >
          1. Información Básica
        </Typography>

        <Box sx={{ p: 2 }}>
          {isEditing ? (
            <Grid container spacing={2}>
              {/* Nombre */}
              <Grid item xs={12}>
                <TextField
                  label="Nombre"
                  name="nom"
                  value={editablePlayer.nom || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* Grupo (Tipo) */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Grupo</InputLabel>
                  <Select
                    name="type"
                    value={editablePlayer.type || ''}
                    onChange={handleChange}
                    label="Grupo"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({ ...prev, type: '' }));
                        }}
                        sx={{
                          visibility: editablePlayer.type ? 'visible' : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="pro">Pro</MenuItem>
                    <MenuItem value="cantera">Cantera</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Groups */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Grupos</InputLabel>
                  <Select
                    multiple
                    value={editablePlayer.groupes || []}
                    renderValue={(selected) =>
                      selected.map((g) => g.libelle).join(', ')
                    }
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({ ...prev, groupes: [] }));
                        }}
                        sx={{
                          visibility: editablePlayer.groupes?.length
                            ? 'visible'
                            : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          width: '90vw',
                          maxWidth: '90vw'
                        }
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      }
                    }}
                  >
                    {allGroups.map((group) => (
                      <MenuItem
                        key={group.id}
                        value={group}
                        sx={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                          py: 1
                        }}
                      >
                        <Checkbox
                          checked={
                            editablePlayer.groupes?.some((g) => g.id === group.id) ||
                            false
                          }
                          onChange={(e) => handleGroupChange(e, group)}
                          style={{ color: group.code_couleur || '#000' }}
                        />
                        <ListItemText primary={group.libelle} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Peso, Altura */}
              <Grid item xs={6}>
                <TextField
                  label="Peso (kg)"
                  name="peso"
                  type="number"
                  value={editablePlayer.peso || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Altura (cm)"
                  name="altura"
                  type="number"
                  value={editablePlayer.altura || editablePlayer.taille || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* Nacionalidad, 2a Nacionalidad */}
              <Grid item xs={12}>
                <NationalitiesSelect
                  value={editablePlayer.nacionalidad || ''}
                  onChange={(e) =>
                    setEditablePlayer((prev) => ({
                      ...prev,
                      nacionalidad: e.target.value
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <NationalitiesSelect2
                  value={editablePlayer.segundaNacionalidad || ''}
                  onChange={(e) =>
                    setEditablePlayer((prev) => ({
                      ...prev,
                      segundaNacionalidad: e.target.value
                    }))
                  }
                />
              </Grid>

              {/* Origen Genético */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Origen Genético</InputLabel>
                  <Select
                    name="origen_genetico"
                    value={editablePlayer.origen_genetico || ''}
                    onChange={handleChange}
                    label="Origen Genético"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            origen_genetico: ''
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.origen_genetico
                            ? 'visible'
                            : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="Africanos (subsahariana) - Camerun, Nigeria, Senegal, Mali, etc.">
                    Africanos (subsahariana) - Camerun, Nigeria, Senegal, Mali, etc.                    </MenuItem>
                    <MenuItem value="Afrocaribenos - Martinica, Republica Dominicana, Puerto Rico, Islas Virgenes, etc.">
                    Afrocaribenos - Martinica, Republica Dominicana, Puerto Rico, Islas Virgenes, etc.                    </MenuItem>
                    <MenuItem value="Balcanes - Serbia, Croacia, Bosnia, Montenegro, Macedonia del Norte, etc.">
                    Balcanes - Serbia, Croacia, Bosnia, Montenegro, Macedonia del Norte, etc.                    </MenuItem>
                    <MenuItem value="Europa Occidental - Francia, Alemania, Espana, Italia, Belgica, etc.">
                    Europa Occidental - Francia, Alemania, Espana, Italia, Belgica, etc.                    </MenuItem>
                    <MenuItem value="Latinoamericanos - Argentina, Brasil, Venezuela, Mexico, etc.">
                    Latinoamericanos - Argentina, Brasil, Venezuela, Mexico, etc.                    </MenuItem>
                    <MenuItem value="Nordafricanos - Tunez, Marruecos, Egipto, etc.">
                    Nordafricanos - Tunez, Marruecos, Egipto, etc.                    </MenuItem>
                    <MenuItem value="Norteamericanos - Estados Unidos, Canada, etc.">
                    Norteamericanos - Estados Unidos, Canada, etc.                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Fecha de nacimiento + Licencia */}
              <Grid item xs={6}>
                <TextField
                  label="Fecha de nacimiento"
                  name="dateNaissance"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={formatDateForInput(editablePlayer.dateNaissance)}
                  onChange={(e) =>
                    setEditablePlayer((prev) => ({
                      ...prev,
                      dateNaissance: e.target.value
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Licencia</InputLabel>
                  <Select
                    name="pasaporte"
                    value={editablePlayer.pasaporte || ''}
                    onChange={handleChange}
                    label="Licencia"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            pasaporte: ''
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.pasaporte
                            ? 'visible'
                            : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="EXT">EXT</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="JFL">JFL</MenuItem>
                    <MenuItem value="COT">COT</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            // READ mode
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {!!player.nom && (
                <Typography>
                  <strong>Nombre:</strong> {player.nom}
                </Typography>
              )}
              {!!player.type && (
                <Typography>
                  <strong>Grupo :</strong>{' '}
                  {player.type.charAt(0).toUpperCase() + player.type.slice(1)}
                </Typography>
              )}
              {!!player.groupes?.length && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {player.groupes.map((g) => (
                    <Box
                      key={g.id}
                      sx={{
                        backgroundColor: g.code_couleur || 'grey',
                        color: '#fff',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: 14
                      }}
                    >
                      {g.libelle}
                    </Box>
                  ))}
                </Box>
              )}
              {!!player.peso && (
                <Typography>
                  <strong>Peso:</strong> {player.peso}kg
                </Typography>
              )}
              {!!player.taille && (
                <Typography>
                  <strong>Altura:</strong> {player.taille} cm
                </Typography>
              )}
              {!!player.nacionalidad && (
                <Typography>
                  <strong>Nacionalidad:</strong> {player.nacionalidad}
                </Typography>
              )}
              {!!player.segundaNacionalidad && (
                <Typography>
                  <strong>Segunda Nacionalidad:</strong> {player.segundaNacionalidad}
                </Typography>
              )}
              {!!player.origen_genetico && (
                <Typography>
                  <strong>Origen Genético:</strong> {player.origen_genetico}
                </Typography>
              )}
              {!!player.dateNaissance && (
                <Typography>
                  <strong>Fecha de Nacimiento:</strong>{' '}
                  {formatDateForDisplay(player.dateNaissance)}
                </Typography>
              )}
              {!!player.pasaporte && (
                <Typography>
                  <strong>Licencia:</strong> {player.pasaporte}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  // Render DESKTOP
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        1. Información Básica
      </Typography>
      {isEditing ? (
        <Grid container spacing={2}>
          {/* Nombre */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Nombre"
              name="nom"
              value={editablePlayer.nom || ''}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Grupo (Tipo) */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Grupo</InputLabel>
              <Select
                name="type"
                value={editablePlayer.type || ''}
                onChange={handleChange}
                label="Grupo"
                endAdornment={
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditablePlayer((prev) => ({ ...prev, type: '' }));
                    }}
                    sx={{
                      visibility: editablePlayer.type ? 'visible' : 'hidden',
                      position: 'absolute',
                      right: 32
                    }}
                    size="small"
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                }
              >
                <MenuItem value="pro">Pro</MenuItem>
                <MenuItem value="cantera">Cantera</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Grupos */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Grupos</InputLabel>
              <Select
                multiple
                value={editablePlayer.groupes || []}
                renderValue={(selected) =>
                  selected.map((g) => g.libelle).join(', ')
                }
                endAdornment={
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditablePlayer((prev) => ({
                        ...prev,
                        groupes: []
                      }));
                    }}
                    sx={{
                      visibility: editablePlayer.groupes?.length
                        ? 'visible'
                        : 'hidden',
                      position: 'absolute',
                      right: 32
                    }}
                    size="small"
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                }
              >
                {allGroups.map((group) => (
                  <MenuItem key={group.id} value={group}>
                    <Checkbox
                      checked={
                        editablePlayer.groupes?.some((g) => g.id === group.id)
                          ? true
                          : false
                      }
                      onChange={(e) => handleGroupChange(e, group)}
                      style={{ color: group.code_couleur || '#000' }}
                    />
                    <ListItemText primary={group.libelle} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Peso, Altura */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Peso (kg)"
              name="peso"
              type="number"
              value={editablePlayer.peso || ''}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Altura (cm)"
              name="altura"
              type="number"
              value={editablePlayer.altura || editablePlayer.taille || ''}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Nacionalidad, 2a Nacionalidad */}
          <Grid item xs={12} md={6}>
            <NationalitiesSelect
              value={editablePlayer.nacionalidad || ''}
              onChange={(e) =>
                setEditablePlayer((prev) => ({
                  ...prev,
                  nacionalidad: e.target.value
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NationalitiesSelect2
              value={editablePlayer.segundaNacionalidad || ''}
              onChange={(e) =>
                setEditablePlayer((prev) => ({
                  ...prev,
                  segundaNacionalidad: e.target.value
                }))
              }
            />
          </Grid>

          {/* Origen Genético */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Origen Genético</InputLabel>
              <Select
                name="origen_genetico"
                value={editablePlayer.origen_genetico || ''}
                onChange={handleChange}
                label="Origen Genético"
                endAdornment={
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditablePlayer((prev) => ({
                        ...prev,
                        origen_genetico: ''
                      }));
                    }}
                    sx={{
                      visibility: editablePlayer.origen_genetico
                        ? 'visible'
                        : 'hidden',
                      position: 'absolute',
                      right: 32
                    }}
                    size="small"
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                }
              >
                <MenuItem value="Africanos (subsahariana) - Camerun, Nigeria, Senegal, Mali, etc.">
                    Africanos (subsahariana) - Camerun, Nigeria, Senegal, Mali, etc.                    </MenuItem>
                    <MenuItem value="Afrocaribenos - Martinica, Republica Dominicana, Puerto Rico, Islas Virgenes, etc.">
                    Afrocaribenos - Martinica, Republica Dominicana, Puerto Rico, Islas Virgenes, etc.                    </MenuItem>
                    <MenuItem value="Balcanes - Serbia, Croacia, Bosnia, Montenegro, Macedonia del Norte, etc.">
                    Balcanes - Serbia, Croacia, Bosnia, Montenegro, Macedonia del Norte, etc.                    </MenuItem>
                    <MenuItem value="Europa Occidental - Francia, Alemania, Espana, Italia, Belgica, etc.">
                    Europa Occidental - Francia, Alemania, Espana, Italia, Belgica, etc.                    </MenuItem>
                    <MenuItem value="Latinoamericanos - Argentina, Brasil, Venezuela, Mexico, etc.">
                    Latinoamericanos - Argentina, Brasil, Venezuela, Mexico, etc.                    </MenuItem>
                    <MenuItem value="Nordafricanos - Tunez, Marruecos, Egipto, etc.">
                    Nordafricanos - Tunez, Marruecos, Egipto, etc.                    </MenuItem>
                    <MenuItem value="Norteamericanos - Estados Unidos, Canada, etc.">
                    Norteamericanos - Estados Unidos, Canada, etc.                    </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Fecha de nacimiento */}
          <Grid item xs={12} md={4}>
            <TextField
              label="Fecha de nacimiento"
              name="dateNaissance"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formatDateForInput(editablePlayer.dateNaissance)}
              onChange={(e) =>
                setEditablePlayer((prev) => ({
                  ...prev,
                  dateNaissance: e.target.value
                }))
              }
            />
          </Grid>

          {/* Licencia */}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Licencia</InputLabel>
              <Select
                name="pasaporte"
                value={editablePlayer.pasaporte || ''}
                onChange={handleChange}
                label="Licencia"
                endAdornment={
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditablePlayer((prev) => ({
                        ...prev,
                        pasaporte: ''
                      }));
                    }}
                    sx={{
                      visibility: editablePlayer.pasaporte
                        ? 'visible'
                        : 'hidden',
                      position: 'absolute',
                      right: 32
                    }}
                    size="small"
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                }
              >
                <MenuItem value="EXT">EXT</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="JFL">JFL</MenuItem>
                <MenuItem value="COT">COT</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        // READ MODE
        <Box sx={{ mt: 1 }}>
          {!!player.nom && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>Nombre:&nbsp; </strong>
              {player.nom}
            </Typography>
          )}
          {!!player.type && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>Grupo :&nbsp; </strong>
              {player.type.charAt(0).toUpperCase() + player.type.slice(1)}
            </Typography>
          )}
          {!!player.groupes?.length && (
            <Box
              sx={{
                display: 'flex',
                ml: '140px',
                flexWrap: 'wrap',
                gap: 1,
                mb: 1
              }}
            >
              {player.groupes.map((g) => (
                <Box
                  key={g.id}
                  sx={{
                    backgroundColor: g.code_couleur || 'grey',
                    color: '#fff',
                    px: 1,
                    py: 0.5,
                    borderRadius: 1
                  }}
                >
                  {g.libelle}
                </Box>
              ))}
            </Box>
          )}
          {(!!player.peso || !!player.taille) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.peso && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Peso:&nbsp; </strong>
                  {player.peso}kg
                </Typography>
              )}
              {!!player.taille && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Altura:&nbsp; </strong>
                  {player.taille}cm
                </Typography>
              )}
            </Box>
          )}
          {(!!player.nacionalidad || !!player.segundaNacionalidad) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.nacionalidad && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>
                    Nacionalidad:&nbsp;{' '}
                  </strong>
                  {player.nacionalidad}
                </Typography>
              )}
              {!!player.segundaNacionalidad && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>
                    Segunda Nacionalidad:&nbsp;{' '}
                  </strong>
                  {player.segundaNacionalidad}
                </Typography>
              )}
            </Box>
          )}
          {!!player.origen_genetico && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>
                Origen Genético:&nbsp;{' '}
              </strong>
              {player.origen_genetico}
            </Typography>
          )}
          {!!player.dateNaissance && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>
                Fecha de Nacimiento:&nbsp;{' '}
              </strong>
              {formatDateForDisplay(player.dateNaissance)}
            </Typography>
          )}
          {!!player.pasaporte && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>Licencia:&nbsp; </strong>
              {player.pasaporte}
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
}

export default InformacionBasica;
