import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  Chip,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Checkbox,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  TablePagination,
  Slider
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import { useBigBoardTable } from '../BigBoardTableContext';

/**
 * Fonction utilitaire pour gérer la couleur de texte
 * en fonction de la couleur de fond (hex).
 */
const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const positionMappings = {
  Base: ['Base', 'Point Guard'],
  Escolta: ['Escolta', 'Shooting Guard', 'Guard'],
  Alerio: ['Alerio', 'Small Forward', 'Swingman'],
  'Ala-Pivot': ['Ala-Pivot', 'Ala-Pívot', 'Power Forward', 'Power Forward / Center'],
  Pivot: ['Pivot', 'Center']
};

/**
 * Ligne de joueur dans le tableau de gauche (disponibles).
 */
const DraggablePlayerRow = ({
  player,
  handlePlayerClick,
  bigBoardPlayers,
  setBigBoardPlayers,
  availablePlayers,
  setAvailablePlayers,
  mediaUrl
}) => {
  // Drag setup
  const [{ isDragging }, dragRef] = useDrag({
    type: 'player',
    item: player,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  // Vérifie si le joueur est déjà dans le Big Board
  const isInBigBoard = bigBoardPlayers.some((p) => p.id === player.id);

  /**
   * Ajoute le joueur au Big Board (si pas déjà présent).
   */
  const movePlayerToBigBoard = (playerToMove) => {
    if (!isInBigBoard) {
      // On incrémente le classement de ceux déjà dans le board
      const updatedExisting = bigBoardPlayers.map((p) => ({
        ...p,
        classement: p.classement + 1
      }));
      // Nouveau joueur avec classement=1
      const newPlayer = { ...playerToMove, classement: 1 };
      setBigBoardPlayers([...updatedExisting, newPlayer]);
      // Retirer de la liste de gauche
      setAvailablePlayers((prev) => prev.filter((pl) => pl.id !== playerToMove.id));
    }
  };

  /**
   * Retire le joueur du Big Board et le remet dans la liste de gauche.
   */
  const removePlayerFromBigBoard = (playerToRemove) => {
    const filtered = bigBoardPlayers.filter((p) => p.id !== playerToRemove.id);
    // Réajuster les classements
    const updated = filtered.map((p, index) => ({
      ...p,
      classement: filtered.length - index
    }));
    setBigBoardPlayers(updated);

    // Réinsère dans la liste de gauche, trié par nom
    setAvailablePlayers((prev) =>
      [...prev, playerToRemove].sort((a, b) => a.nom.localeCompare(b.nom))
    );
  };

  /**
   * Checkbox : coche => ajout, décoche => retrait
   */
  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      movePlayerToBigBoard(player);
    } else {
      removePlayerFromBigBoard(player);
    }
  };

  return (
    <TableRow
      ref={dragRef}
      key={player.id}
      hover
      sx={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
    >
      <TableCell padding="checkbox">
        <Checkbox checked={isInBigBoard} onChange={handleCheckboxChange} />
      </TableCell>
      <TableCell>
        <Avatar src={`${mediaUrl}/photos/${player.id}.png`} alt={player.nom} />
      </TableCell>
      <TableCell>
        <Box
          component="a"
          onClick={(e) => {
            e.preventDefault();
            handlePlayerClick(player);
          }}
          sx={{
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer'
            }
          }}
        >
          {player.nom}
        </Box>
      </TableCell>
      <TableCell>{player.poste}</TableCell>
      <TableCell>{player.team || '-'}</TableCell>
      <TableCell>{player.league_1 || '-'}</TableCell>
    </TableRow>
  );
};

const BigBoardModif = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  const { tags, refreshData } = useBigBoardTable();

  // Loading / Error
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Nom du BigBoard, type, tags sélectionnés
  const [bigBoardName, setBigBoardName] = useState('');
  const [bigBoardType, setBigBoardType] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  // Joueurs du BigBoard (droite) + joueurs dispo (gauche) + tous (filtrés par type)
  const [bigBoardPlayers, setBigBoardPlayers] = useState([]);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);

  // -- Nouveaux états pour Grupos --
  const [groups, setGroups] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);

  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  // -- Fusion de nameFilter + teamFilter => searchFilter --
  const [searchFilter, setSearchFilter] = useState('');

  // -- Autres filtres --
  const [positionFilter, setPositionFilter] = useState([]);
  const [heightFilter, setHeightFilter] = useState([130, 230]);
  const [ageFilter, setAgeFilter] = useState([12, 45]);
  const [leagueFilter, setLeagueFilter] = useState([]);
  const [uniqueLeagues, setUniqueLeagues] = useState([]);

  /**
   * Calcule l'âge d'un joueur à partir de son année de naissance.
   */
  const calculateAge = (anneeNaissance) => {
    const currentYear = new Date().getFullYear();
    return currentYear - anneeNaissance;
  };

  /**
   * Récupération du Big Board existant, puis récupération de tous les joueurs filtrés par type.
   */
  useEffect(() => {
    const fetchBigBoard = async () => {
      try {
        setLoading(true);

        // 1) Charger le BigBoard
        const response = await axios.get(`${backendUrl}/api/bigboard/${id}`, {
          params: { section }
        });
        const board = response.data;

        setBigBoardName(board.libelle || '');
        setSelectedTags(board.tags || []);

        // Type du board (Pro / Cantera), en minuscules
        const boardType = board.type ? board.type.toLowerCase() : '';
        setBigBoardType(boardType);

        // Joueurs déjà dans le bigboard, triés selon classement
        const sortedPlayers = board.players
          ? [...board.players].sort((a, b) => b.classement - a.classement)
          : [];
        setBigBoardPlayers(sortedPlayers);

        // 2) Charger tous les joueurs
        const playersResponse = await axios.get(`${backendUrl}/api/players`, {
          params: { section }
        });
        const allFetched = playersResponse.data.players || [];

        // 3) Filtrer par type
        const filteredByType = allFetched.filter((p) => {
          if (!p.type) return false;
          return p.type.toLowerCase() === boardType;
        });

        setAllPlayers(filteredByType);
        setError(null);
      } catch (err) {
        console.error('Error al recuperar el Ranking:', err);
        setError('No se puede cargar el Ranking.');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBigBoard();
    }
  }, [id, backendUrl, section]);

  /**
   * Récupération de la liste des groupes (pour le filtre "Filtrar por grupos").
   */
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/groups?section=${section}`);
        setGroups(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des groupes:', error);
      }
    };
    fetchGroups();
  }, [backendUrl, section]);

  /**
   * Met à jour la liste des joueurs disponibles et les ligues uniques
   * chaque fois que bigBoardPlayers ou allPlayers change.
   */
  useEffect(() => {
    const boardPlayerIds = bigBoardPlayers.map((p) => p.id);
    const available = allPlayers.filter((p) => !boardPlayerIds.includes(p.id));

    const leagues = Array.from(
      new Set(
        available
          .map((player) => player.league_1)
          .filter((league) => league && league.trim() !== '' && league.trim() !== 'null')
      )
    ).sort();

    setUniqueLeagues(leagues);
    setAvailablePlayers(available);
  }, [bigBoardPlayers, allPlayers]);

  /**
   * Filtrage des joueurs disponibles (colonne de gauche).
   */
  const filteredAvailablePlayers = availablePlayers.filter((player) => {
    const playerAge = calculateAge(player.annee_naissance);

    // Filtre texte (nom + équipe) fusionné
    const term = searchFilter.toLowerCase();
    const matchesSearch =
      player.nom.toLowerCase().includes(term) ||
      (player.team && player.team.toLowerCase().includes(term));

    if (!matchesSearch) return false;

    // Filtre position
    if (positionFilter.length > 0) {
      const matchPosition = positionFilter.some((pos) =>
        positionMappings[pos].some(
          (mappedPos) => player.poste?.toLowerCase() === mappedPos.toLowerCase()
        )
      );
      if (!matchPosition) return false;
    }

    // Filtre ligue
    if (leagueFilter.length > 0 && !leagueFilter.includes(player.league_1)) {
      return false;
    }

    // Filtre groupes
    if (groupFilter.length > 0) {
      const playerGroupIds = (player.groupes || []).map((g) => g.id);
      const hasOverlap = groupFilter.some((gId) => playerGroupIds.includes(gId));
      if (!hasOverlap) return false;
    }

    // Filtre taille
    if (player.taille < heightFilter[0] || player.taille > heightFilter[1]) {
      return false;
    }

    // Filtre âge
    if (playerAge < ageFilter[0] || playerAge > ageFilter[1]) {
      return false;
    }

    return true;
  });

  /**
   * Enregistrement du BigBoard (PUT).
   * On inverse l'ordre des joueurs pour que l'index 0 ait le plus grand classement.
   */
  const handleSave = async () => {
    if (!bigBoardName.trim()) {
      setError('El nombre del bigboard no puede estar vacío');
      return;
    }

    try {
      // On inverse l'ordre
      const playersToSave = [...bigBoardPlayers].reverse();

      await axios.put(`${backendUrl}/api/bigboard/${id}`, {
        section,
        libelle: bigBoardName,
        players: playersToSave.map((player) => player.id),
        tags: selectedTags.map((tag) => tag.id),
        type: bigBoardType === 'pro' ? 'Pro' : 'Cantera'
      });

      navigate(-1);
      setTimeout(() => {
        refreshData();
      }, 100);
    } catch (err) {
      console.error('Error al actualizar el Ranking:', err);
      setError(err.response?.data?.message || 'Error al actualizar el Ranking.');
    }
  };

  /**
   * Clique sur le nom du joueur => fiche
   */
  const handlePlayerClick = (player) => {
    navigate(`/players/${player.id}`);
  };

  /**
   * Retirer un joueur du BigBoard
   */
  const removePlayerFromBigBoard = (player) => {
    const filtered = bigBoardPlayers.filter((p) => p.id !== player.id);
    const updated = filtered.map((p, index) => ({
      ...p,
      classement: filtered.length - index
    }));
    setBigBoardPlayers(updated);

    setAvailablePlayers((prev) =>
      [...prev, player].sort((a, b) => a.nom.localeCompare(b.nom))
    );
  };

  /**
   * Ajouter un joueur dans le BigBoard au drop
   */
  const movePlayerToBigBoard = (player) => {
    const isAlreadyInBoard = bigBoardPlayers.some((p) => p.id === player.id);
    if (!isAlreadyInBoard) {
      const updated = bigBoardPlayers.map((p) => ({
        ...p,
        classement: p.classement + 1
      }));
      const newPlayer = { ...player, classement: 1 };
      setBigBoardPlayers([...updated, newPlayer]);
      setAvailablePlayers((prev) => prev.filter((p) => p.id !== player.id));
    }
  };

  /**
   * Réarrange l'ordre (drag interne).
   */
  const movePlayerInBigBoard = (dragIndex, hoverIndex) => {
    const newPlayers = [...bigBoardPlayers];
    const [movedPlayer] = newPlayers.splice(dragIndex, 1);
    newPlayers.splice(hoverIndex, 0, movedPlayer);

    const updated = newPlayers.map((p, index) => ({
      ...p,
      classement: newPlayers.length - index
    }));
    setBigBoardPlayers(updated);
  };

  /**
   * Joueur déjà dans le BigBoard, draggable pour réordonner.
   */
  const DraggableBigBoardPlayer = ({ player, index }) => {
    const ref = useRef(null);

    const [, drag] = useDrag({
      type: 'bigboard-player',
      item: { index }
    });

    const [, drop] = useDrop({
      accept: 'bigboard-player',
      hover: (item) => {
        if (item.index !== index) {
          movePlayerInBigBoard(item.index, index);
          item.index = index;
        }
      }
    });

    drag(drop(ref));

    return (
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0.5,
          mb: 0.5,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          cursor: 'move',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <Typography sx={{ mr: 2, minWidth: '30px' }}>
          {player.classement}
        </Typography>
        <Avatar
          src={`${mediaUrl}/photos/${player.id}.png`}
          alt={player.nom}
          sx={{ mr: 2 }}
        />
        <Typography sx={{ flexGrow: 1 }}>{player.nom}</Typography>
        <IconButton onClick={() => removePlayerFromBigBoard(player)} color="error">
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  };

  /**
   * Zone de drop du BigBoard
   */
  const BigBoardDropZone = () => {
    const [{ isOver }, dropRef] = useDrop({
      accept: 'player',
      drop: (player) => movePlayerToBigBoard(player),
      collect: (monitor) => ({ isOver: monitor.isOver() })
    });

    // Affiche à l'envers pour que classement=1 soit en bas
    const displayPlayers = [...bigBoardPlayers].reverse();

    return (
      <Paper
        ref={dropRef}
        sx={{
          p: 2,
          minHeight: '500px',
          backgroundColor: isOver ? 'rgba(255, 152, 0, 0.1)' : '#f5f5f5'
        }}
      >
        <Typography variant="h6" gutterBottom>
          Ranking
        </Typography>
        {displayPlayers.map((player, i) => (
          <DraggableBigBoardPlayer
            key={player.id}
            player={player}
            index={bigBoardPlayers.length - 1 - i}
          />
        ))}
        {bigBoardPlayers.length === 0 && (
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              mt: 2
            }}
          >
            Arrastra jugador@s aquí
          </Typography>
        )}
      </Paper>
    );
  };

  // Affichage Loading / Erreur
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error" sx={{ m: 2 }}>{error}</Alert>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ p: 3 }}>

        {/* Filtres */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Filtros
          </Typography>
          <Grid container spacing={2}>
            {/* FUSION : champ searchFilter (nom+équipe) */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Filtrar por Nombre/Equipo"
                variant="outlined"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </Grid>

            {/* Nouveau MultiSelect pour Grupos */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="group-filter-label">Filtrar por Grupos</InputLabel>
                <Select
                  labelId="group-filter-label"
                  multiple
                  value={groupFilter}
                  onChange={(e) => setGroupFilter(e.target.value)}
                  renderValue={(selected) => {
                    // selected est un tableau d'IDs
                    const labels = groups
                      .filter((g) => selected.includes(g.id))
                      .map((g) => g.libelle);
                    return labels.join(', ');
                  }}
                >
                  {groups.map((g) => (
                    <MenuItem key={g.id} value={g.id}>
                      <Checkbox checked={groupFilter.includes(g.id)} />
                      <ListItemText primary={g.libelle} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Filtre positions */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="position-filter-label">Filtrar por Posición</InputLabel>
                <Select
                  labelId="position-filter-label"
                  multiple
                  value={positionFilter}
                  onChange={(e) => setPositionFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {Object.entries(positionMappings).map(([position, equivalents]) => (
                    <MenuItem key={position} value={position}>
                      <Checkbox checked={positionFilter.includes(position)} />
                      <ListItemText
                        primary={position}
                        secondary={equivalents.slice(1).join(', ')}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Filtre ligues */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="league-filter-label">Filtrar por Liga</InputLabel>
                <Select
                  labelId="league-filter-label"
                  multiple
                  value={leagueFilter}
                  onChange={(e) => setLeagueFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {uniqueLeagues.map((league) => (
                    <MenuItem key={league} value={league}>
                      <Checkbox checked={leagueFilter.includes(league)} />
                      <ListItemText primary={league} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ px: 3 }}>
                <Typography gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
                  Filtrar por Altura (130 a 230 cm)
                </Typography>
                <Slider
                  value={heightFilter}
                  onChange={(e, newValue) => setHeightFilter(newValue)}
                  valueLabelDisplay="auto"
                  min={130}
                  max={230}
                  sx={{ color: '#ff9800' }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ px: 3 }}>
                <Typography gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
                  Filtrar por Edad (12 a 45 años)
                </Typography>
                <Slider
                  value={ageFilter}
                  onChange={(e, newValue) => setAgeFilter(newValue)}
                  valueLabelDisplay="auto"
                  min={12}
                  max={45}
                  sx={{ color: '#ff9800' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Configuration du Big Board (Nom + Tags) */}
        <Box sx={{ mb: 6, mt: 6 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                label="Nombre del Ranking"
                value={bigBoardName}
                onChange={(e) => setBigBoardName(e.target.value)}
                error={!bigBoardName.trim()}
                helperText={!bigBoardName.trim() ? 'El nombre es obligatorio' : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Tags</InputLabel>
                <Select
                  multiple
                  value={selectedTags}
                  onChange={(e) => setSelectedTags(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((tag) => (
                        <Chip
                          key={tag.id}
                          label={tag.libelle}
                          style={{
                            backgroundColor: tag.code_couleur,
                            color: getContrastColor(tag.code_couleur)
                          }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag.id} value={tag}>
                      <Checkbox checked={selectedTags.some((t) => t.id === tag.id)} />
                      <ListItemText primary={tag.libelle} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCELAR
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{
                backgroundColor: '#1976d2',
                '&:hover': {
                  backgroundColor: '#1565c0'
                }
              }}
            >
              GUARDAR CAMBIOS
            </Button>
          </Box>
        </Box>

        {/* Tableau de gauche (joueurs) + dropzone (droite) */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox disabled />
                    </TableCell>
                    <TableCell>Avatar</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Posición</TableCell>
                    <TableCell>Equipo</TableCell>
                    <TableCell>Liga</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAvailablePlayers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((player) => (
                      <DraggablePlayerRow
                        key={player.id}
                        player={player}
                        handlePlayerClick={handlePlayerClick}
                        bigBoardPlayers={bigBoardPlayers}
                        setBigBoardPlayers={setBigBoardPlayers}
                        availablePlayers={availablePlayers}
                        setAvailablePlayers={setAvailablePlayers}
                        mediaUrl={mediaUrl}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={filteredAvailablePlayers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              rowsPerPageOptions={[10]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <BigBoardDropZone />
          </Grid>
        </Grid>
      </Box>
    </DndProvider>
  );
};

export default BigBoardModif;
