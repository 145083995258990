import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Tabs,
  Tooltip,
  Tab,
  Box,
  Avatar,
  Typography,
  Chip,
  Paper,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Grid,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  CircularProgress,
  Alert,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  FormGroup,
  FormControlLabel
} from '@mui/material';

import {
  ArrowBack,
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  PhotoCamera,
  Image as ImageIcon,
  Edit,
  OpenInNew,
  PictureAsPdf as PictureAsPdfIcon
} from '@mui/icons-material';

import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import InformeTab from '../components/InformeTab';
import IntelTab from '../components/IntelTab';
import TechnicaTab from '../components/TechnicaTab';
import PDPTab from '../components/PDPTab';

// Sous-formulaires d'édition du profil
import InformacionBasica from '../components/profile/InformacionBasica';
import CaracteristicasJugador from '../components/profile/CaracteristicasJugador';
import SituacionActual from '../components/profile/SituacionActual';
import ExperienciaProfesional from '../components/profile/ExperienciaProfesional';
import InformacionContractual from '../components/profile/InformacionContractual';
import InformacionAdicional from '../components/profile/InformacionAdicional';


// ----------------- Helpers -----------------

// Redimensionner une image avant upload
const resizeImageFile = (file, maxDimension = 800) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        let { width, height } = img;
        if (width > height && width > maxDimension) {
          height = height * (maxDimension / width);
          width = maxDimension;
        } else if (height > maxDimension) {
          width = width * (maxDimension / height);
          height = maxDimension;
        }
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(
          (blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            resolve(resizedFile);
          },
          'image/jpeg',
          0.8
        );
      };
      img.onerror = reject;
      img.src = event.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const formatDateForDisplay = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatDateForBackend = (input) => {
  if (!input || input.includes('T')) return input;
  const [year, month, day] = input.split('-');
  if (year && month && day) {
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
  return input;
};

// Ajoute une ligne [label, valeur] uniquement si la valeur est non vide
function pushNonEmptyRow(arr, label, value, suffix = '') {
  if (value && value.toString().trim() !== '') {
    arr.push([label, suffix ? value + suffix : value]);
  }
}


// ----------------- Fonctions PDF -----------------

// Convertir du HTML en texte brut avec gestion améliorée pour <p> et <div>
// Pour les balises <br>, on renvoie uniquement "\n" sans saut supplémentaire.
const convertHtmlToText = (html) => {
  if (!html) return '';
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  function traverse(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.nodeValue;
    }
    if (node.nodeType === Node.ELEMENT_NODE) {
      const tag = node.tagName.toLowerCase();
      let text = '';
      if (tag === 'br') {
        return '\n';
      }
      // Pour <p> et <div>, ajouter un saut de ligne avant et après
      if (tag === 'p' || tag === 'div') {
        node.childNodes.forEach(child => {
          text += traverse(child);
        });
        text += ' ';
        return text;
      }
      if (tag === 'ol') {
        let index = 1;
        node.childNodes.forEach(child => {
          if (child.nodeType === Node.ELEMENT_NODE && child.tagName.toLowerCase() === 'li') {
            text += index + '. ' + traverse(child) + '\n';
            index++;
          } else {
            text += traverse(child);
          }
        });
        return text;
      }
      if (tag === 'ul') {
        node.childNodes.forEach(child => {
          if (child.nodeType === Node.ELEMENT_NODE && child.tagName.toLowerCase() === 'li') {
            text += '• ' + traverse(child) + '\n';
          } else {
            text += traverse(child);
          }
        });
        return text;
      }
      if (tag === 'li') {
        text += Array.from(node.childNodes).map(child => traverse(child)).join('');
        return text;
      }
      node.childNodes.forEach(child => {
        text += traverse(child);
      });
      return text;
    }
    return '';
  }
  let result = traverse(doc.body);
  result = result.replace(/\n{2,}/g, "\n").trim();
  return result;
};

// Transforme "video"/"live" en "Video"/"Live"
const formatScoutingType = (visu) => {
  if (!visu) return '';
  const lower = visu.toLowerCase();
  if (lower.includes('video')) return 'Video';
  if (lower.includes('live')) return 'Live';
  return visu;
};

// Dessiner un rectangle coloré (pour Flag Behaviour / Injury)
const drawColorBox = (doc, x, y, size, color) => {
  doc.setFillColor(color.r, color.g, color.b);
  doc.rect(x, y, size, size, 'F');
};

/**
 * Fonction améliorée pour dessiner un bloc de texte long avec saut de page.
 * On passe également les paramètres de police souhaités pour assurer l’homogénéité.
 */
const drawTextBlock = (
  doc,
  text,
  yPos,
  marginLeft,
  contentWidth,
  pageHeight,
  bottomMargin,
  fontFamily = 'helvetica',
  fontStyle = 'normal',
  fontSize = 11
) => {
  // S'assurer que la police et la taille souhaitées sont appliquées
  if (doc.getFont() !== fontFamily || doc.getFontType() !== fontStyle) {
    doc.setFont(fontFamily, fontStyle);
  }
  if (doc.getFontSize() !== fontSize) {
    doc.setFontSize(fontSize);
  }
  const lines = doc.splitTextToSize(text, contentWidth);
  for (let i = 0; i < lines.length; i++) {
    if (doc.getFontSize() !== fontSize) {
      doc.setFontSize(fontSize);
    }
    if (yPos + fontSize > pageHeight - bottomMargin) {
      doc.addPage();
      addPageTemplate(doc);
      // Réinitialiser les paramètres de police sur la nouvelle page
      doc.setFont(fontFamily, fontStyle);
      doc.setFontSize(fontSize);
      yPos = 20;
    }
    doc.text(lines[i], marginLeft, yPos);
    yPos += 6;
  }
  return yPos;
};

// Fonction qui dessine le template de page : un cadre avec un liseret orange et une bande orange tout en bas
const addPageTemplate = (doc) => {
  const pageNumber = doc.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  // Dessiner le cadre (de 10 à pageHeight-10)
  doc.setDrawColor('#EA7F00');
  doc.setLineWidth(2);
  doc.rect(10, 10, pageWidth - 20, pageHeight - 20);
  // Dessiner la bande orange en bas, flush avec la bordure inférieure
  const bandHeight = 20;
  const bandY = pageHeight - 10 - bandHeight; // 10 = marge inférieure
  doc.setFillColor(234, 127, 0);
  doc.rect(10, bandY, pageWidth - 20, bandHeight, 'F');
  // Ajouter logos, texte et numéro de page dans la bande
  const logoSize = 16;
  try {
    const logoY = bandY + (bandHeight - logoSize) / 2;
    // Logo de Valencia Basket à gauche (exemple, si vous avez un logo local ou externe)
    doc.addImage(
      `${process.env.PUBLIC_URL}/assets/LogoValenciaBasket.png`,
      'PNG',
      15,
      logoY,
      logoSize,
      logoSize
    );
    // Texte centré
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(9);
    doc.text(
      `VALENCIA BASKET - DEPARTAMENTO DE SCOUTING`,
      pageWidth / 2,
      bandY + bandHeight / 2 + 3,
      { align: 'center' }
    );
    // Logo d'Alquería à droite (exemple, si vous avez un logo local ou externe)
    doc.addImage(
      `${process.env.PUBLIC_URL}/assets/alqueria-del-basket.png`,
      'PNG',
      pageWidth - 15 - logoSize,
      logoY,
      logoSize,
      logoSize
    );
    // Numéro de page centré
    doc.setFontSize(8);
    doc.text(pageNumber.toString(), pageWidth / 2, bandY + bandHeight - 3, { align: 'center' });
  } catch (error) {
    console.error('Error adding images to PDF page:', error);
  }
  // Réinitialiser la couleur du texte en noir pour le reste du contenu
  doc.setTextColor(0);
};


// ----------------- Composant PlayerProfile -----------------

const PlayerProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  // États principaux
  const [player, setPlayer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editablePlayer, setEditablePlayer] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [allGroups, setAllGroups] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [avatarTimestamp, setAvatarTimestamp] = useState(Date.now());
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Données supplémentaires
  const [hasInformeData, setHasInformeData] = useState(false);
  const [hasIntelData, setHasIntelData] = useState(false);
  const [hasTechnicaData, setHasTechnicaData] = useState(false);
  const [hasPDPData, setHasPDPData] = useState(false);

  // Listes pour le PDF
  const [informeList, setInformeList] = useState([]);
  const [intelList, setIntelList] = useState([]);

  // Autres listes
  const [teams, setTeams] = useState([]);
  const [leagues1, setLeagues1] = useState([]);
  const [leagues2, setLeagues2] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [userRole, setUserRole] = useState(null);

  // Dialog PDF et sélections
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [checkedInformes, setCheckedInformes] = useState([]);
  const [checkedIntels, setCheckedIntels] = useState([]);
  const [masterCheckInformes, setMasterCheckInformes] = useState(false);
  const [masterCheckIntels, setMasterCheckIntels] = useState(false);

  // Cases à cocher pour les sections à inclure
  const [includeProfileInfo, setIncludeProfileInfo] = useState(false);
  const [includeCaracteristicas, setIncludeCaracteristicas] = useState(false);
  const [includeExperiencia, setIncludeExperiencia] = useState(false);
  const [includeContrato, setIncludeContrato] = useState(false);
  const [selectAllSections, setSelectAllSections] = useState(false);

  // Données contractuelles
  const priceRanges = [
    '0-50k€', '50-100k€', '100-150k€', '150-200k€', '200-250k€', '250-300k€', '300-350k€',
    '350-400k€', '400-450k€', '450-500k€', '500-550k€', '550-600k€', '600-650k€', '650-700k€',
    '700-750k€', '750-800k€', '800-850k€', '850-900k€', '900-950k€', '950k€-1M€', '1M€-1.2M€',
    '1.2M€-1.4M€', '1.4M€-1.6M€', '1.6M€-1.8M€', '1.8M€-2M€', '2M€-2.2M€', '2.2M€-2.4M€', '2.4M€-2.5M€'
  ];
  const buyoutOptions = [...priceRanges];
  const contratos = [
    '2-Way', 'Exhibit-10', '1 año', '1+1', '2 años', '3 años', 'On Loan'
  ];

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUserRole(user?.role?.toLowerCase());
  }, []);

  // Récupération des listes (teams, leagues, agencies)
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/teams`, { params: { section } });
        setTeams(response.data.teams || []);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };
    const fetchLeagues1 = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/leagues1`, { params: { section } });
        setLeagues1(response.data.leagues1 || []);
      } catch (error) {
        console.error('Error fetching leagues1:', error);
      }
    };
    const fetchLeagues2 = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/leagues2`, { params: { section } });
        setLeagues2(response.data.leagues2 || []);
      } catch (error) {
        console.error('Error fetching leagues2:', error);
      }
    };
    const fetchAgencies = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/agencies`, { params: { section } });
        setAgencies(response.data.agencies || []);
      } catch (error) {
        console.error('Error fetching agencies:', error);
      }
    };
    fetchTeams();
    fetchLeagues1();
    fetchLeagues2();
    fetchAgencies();
  }, [backendUrl, section]);

  // Récupération du joueur
  useEffect(() => {
    const fetchPlayer = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${backendUrl}/api/players/${id}?section=${section}`);
        const mappedPlayer = {
          ...response.data,
          dateNaissance: response.data.date_naissance,
          nacionalidad: response.data.nationalite,
          segundaNacionalidad: response.data.nationalite_2,
          seleccionNacional: response.data.selection_national,
          peso: response.data.poids,
          rolOfensivo: response.data.offensive_role,
          rolDefensivo: response.data.defensive_role,
          pasaporte: response.data.passport,
          estatus: response.data.statut,
          equipo: response.data.team,
          liga1: response.data.league_1,
          liga2: response.data.league_2,
          equipoPreDraft: response.data.pre_draft_team,
          añoDraft: response.data.draft_year,
          posicionDraft: response.data.draft_pick,
          experienciaAcb: response.data.acb_experience,
          nbaExperience: response.data.nba_experience,
          agencia: response.data.agency,
          comparacionPro: response.data.pro_comparaison,
          comportamiento: response.data.flag_behaviour,
          rangoPrecios: response.data.price_range,
          contrato: response.data.contract,
          nivel: response.data.level,
          proyeccion: response.data.proyeccion,
          jeJiType: response.data.je_ji || '',
          jeJiAttributes: response.data.selection_je_ji ? response.data.selection_je_ji.split(',') : [],
          pit: response.data.pit,
          eurocamp: response.data.eurocamp,
          injury: response.data.injury,
          experiencaEuropa: response.data.experienca_europa,
          shootingRange: response.data.shooting_range,
          competicionEuropa: response.data.competicion_europa,
          origen_genetico: response.data.origen_genetico
        };
        setPlayer(mappedPlayer);
        setEditablePlayer(mappedPlayer);
        setError(null);
      } catch (err) {
        setError(err.response?.data?.message || 'Error al cargar los datos del jugador');
        console.error('Error fetching player:', err);
      } finally {
        setIsLoading(false);
      }
    };
    if (id) fetchPlayer();
  }, [id, backendUrl, section]);

  // Récupération des données supplémentaires (informe, intel, technique, pdp)
  useEffect(() => {
    const fetchExtraData = async () => {
      if (!player) return;
      try {
        const informeRes = await axios.get(`${backendUrl}/api/informe`, { params: { playerId: player.id, section } });
        setHasInformeData(informeRes.data.length > 0);
        setInformeList(informeRes.data);

        const intelRes = await axios.get(`${backendUrl}/api/intel`, { params: { playerId: player.id, section } });
        setHasIntelData(intelRes.data.length > 0);
        setIntelList(intelRes.data);

        const technicaRes = await axios.get(`${backendUrl}/api/technique`, { params: { playerId: player.id, section } });
        setHasTechnicaData(technicaRes.data.length > 0);

        const pdpRes = await axios.get(`${backendUrl}/api/tecnification/player/${player.id}/fiches`, { params: { section } });
        setHasPDPData(pdpRes.data.length > 0);
      } catch (error) {
        console.error('Error al recuperar los datos extra:', error);
      }
    };
    fetchExtraData();
  }, [player, section, backendUrl]);

  // Récupération des groupes
  useEffect(() => {
    const fetchGroups = async () => {
      if (player) {
        try {
          const response = await axios.get(`${backendUrl}/api/groups`, { params: { section } });
          setAllGroups(response.data);
        } catch (error) {
          console.error('Error al recuperar grupos:', error);
        }
      }
    };
    fetchGroups();
  }, [player, section, backendUrl]);

  // Handlers
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setEditablePlayer((prev) => ({ ...prev, [name]: fieldValue }));
  };

  const handlePhotoCapture = (file) => {
    setSelectedImage(file);
    setSelectedImagePreview(URL.createObjectURL(file));
    setOpenPhotoDialog(false);
  };

  const handlePhotoSelect = (file) => {
    setSelectedImage(file);
    setSelectedImagePreview(URL.createObjectURL(file));
    setOpenPhotoDialog(false);
  };

  const handleGroupChange = (e, group) => {
    setEditablePlayer((prev) => {
      const isGroupSelected = prev.groupes?.some((g) => g.id === group.id);
      const updatedGroups = isGroupSelected
        ? prev.groupes.filter((g) => g.id !== group.id)
        : [...(prev.groupes || []), group];
      return { ...prev, groupes: updatedGroups };
    });
  };

  // Mise à jour du joueur
  const handleUpdate = async () => {
    const dateNaissanceFormatted = formatDateForBackend(editablePlayer.dateNaissance);
    const anneeNaissanceFormatted = dateNaissanceFormatted ? new Date(dateNaissanceFormatted).getFullYear() : '';
    const formData = new FormData();
    formData.append('nom', editablePlayer.nom || '');
    formData.append('type', editablePlayer.type || '');
    formData.append('annee_naissance', anneeNaissanceFormatted || '');
    formData.append('date_naissance', dateNaissanceFormatted || '');
    formData.append('taille', editablePlayer.altura || editablePlayer.taille || '');
    formData.append('poids', editablePlayer.peso || '');
    formData.append('poste', editablePlayer.poste || '');
    formData.append('nationalite', editablePlayer.nacionalidad || '');
    formData.append('nationalite_2', editablePlayer.segundaNacionalidad || '');
    formData.append('selection_national', editablePlayer.seleccionNacional || '');
    formData.append('mano', editablePlayer.mano || '');
    formData.append('passport', editablePlayer.pasaporte || '');
    formData.append('statut', editablePlayer.estatus || '');
    formData.append('team', editablePlayer.equipo || '');
    formData.append('league_1', editablePlayer.liga1 || '');
    formData.append('league_2', editablePlayer.liga2 || '');
    formData.append('pre_draft_team', editablePlayer.equipoPreDraft || '');
    formData.append('draft_year', editablePlayer.añoDraft || '');
    formData.append('draft_pick', editablePlayer.posicionDraft || '');
    formData.append('acb_experience', editablePlayer.experienciaAcb || '');
    formData.append('nba_experience', editablePlayer.nbaExperience || '');
    formData.append('level', editablePlayer.nivel || '');
    formData.append('proyeccion', editablePlayer.proyeccion || '');
    formData.append('agency', editablePlayer.agencia || '');
    formData.append('pro_comparaison', editablePlayer.comparacionPro || '');
    formData.append('flag_behaviour', editablePlayer.comportamiento || '');
    formData.append('injury', editablePlayer.injury || '');
    formData.append('price_range', editablePlayer.rangoPrecios || '');
    formData.append('contract', editablePlayer.contrato || '');
    formData.append('url', editablePlayer.url || '');
    formData.append('pit', editablePlayer.pit || '');
    formData.append('eurocamp', editablePlayer.eurocamp || '');
    // JE/JI
    formData.append('je_ji', editablePlayer.jeJiType || '');
    const jeJiAttributesValue = editablePlayer.jeJiAttributes?.length ? editablePlayer.jeJiAttributes.join(',') : '';
    formData.append('selection_je_ji', jeJiAttributesValue);
    // Rôles
    formData.append('offensive_role', editablePlayer.rolOfensivo || '');
    formData.append('defensive_role', editablePlayer.rolDefensivo || '');
    // Groupes
    if (editablePlayer.groupes) {
      formData.append('groupes', JSON.stringify(editablePlayer.groupes.map((g) => g.id)));
    }
    // Champs supplémentaires
    formData.append('experienca_europa', editablePlayer.experiencaEuropa || '');
    formData.append('shooting_range', editablePlayer.shootingRange || '');
    formData.append('competicion_europa', editablePlayer.competicionEuropa || '');
    formData.append('buyout', editablePlayer.buyout || '');
    formData.append('origen_genetico', editablePlayer.origen_genetico || '');
    formData.append('slvegas', editablePlayer.slvegas || '');
    formData.append('experienca_fuera', editablePlayer.experienca_fuera || '');
    formData.append('experienca_ncaa', editablePlayer.experienca_ncaa || '');
    // Photo
    if (selectedImage) {
      formData.append('avatar', selectedImage);
    }
    try {
      await axios.put(`${backendUrl}/api/players/${editablePlayer.id}?section=${section}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      setPlayer({
        ...editablePlayer,
        date_naissance: dateNaissanceFormatted,
        annee_naissance: anneeNaissanceFormatted
      });
      setIsEditing(false);
      setAvatarTimestamp(Date.now());
      if (isMobile) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error al actualizar el jugador');
      console.error('Update error:', error);
    }
  };

  // ----------------- Génération du PDF -----------------

  const generatePDF = async () => {
    let imgData = null;
    try {
      if (selectedImagePreview) {
        const imgResponse = await fetch(selectedImagePreview);
        if (imgResponse.ok) {
          const blob = await imgResponse.blob();
          imgData = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
        }
      } else {
        const avatarUrl = `${mediaUrl}/photos/${player.id}.png?${avatarTimestamp}`;
        const response = await fetch(`${backendUrl}/api/proxy-image?url=${encodeURIComponent(avatarUrl)}`);
        if (response.ok) {
          const blob = await response.blob();
          imgData = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération de la photo:', error);
    }

    const doc = new jsPDF();

    // -- ICI LA CORRECTION : on appelle le template pour la première page --
    addPageTemplate(doc);

    // Pour que chaque page générée par autoTable redessine le template
    doc.autoTableSetDefaults({
      didDrawPage: function () {
        // On s'assure que si une autoTable crée une nouvelle page,
        // alors le template est appliqué sur chaque nouvelle page
        addPageTemplate(doc);
      }
    });

    // Définition des marges et dimensions
    const bottomMargin = 30; // marge basse pour éviter que la bande orange masque le contenu
    const marginLeft = 20;
    const marginRight = 20;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const contentWidth = pageWidth - marginLeft - marginRight;

    // Fonction pour ajouter une nouvelle page si nécessaire (en réinitialisant la police)
    let yPos = 20;
    const addNewPageIfNeeded = (neededSpace = 20) => {
      if (yPos + neededSpace > pageHeight - bottomMargin) {
        doc.addPage();
        addPageTemplate(doc);
        // Réinitialiser la police et la taille pour la nouvelle page
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(11);
        yPos = 20;
      }
    };

    // Entête : Photo et nom
    const photoSize = 35;
    if (imgData) {
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.rect(marginLeft, yPos, photoSize + 4, photoSize + 4);
      doc.addImage(imgData, 'PNG', marginLeft + 2, yPos + 2, photoSize, photoSize);
    }
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(26);
    doc.setTextColor(0);
    const nameX = marginLeft + photoSize + 10;
    const nameYPos = yPos + photoSize / 2 + 5;
    doc.text(player.nom || '', nameX, nameYPos, { align: 'left' });
    yPos += photoSize + 10;

    // Afficher les tags
    if (player.tags && player.tags.length > 0) {
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(12);
      const tagsLine = player.tags
        .map((tag) =>
          tag.source === 'big_board'
            ? `Ranking: ${tag.libelle}`
            : `Seguimiento: ${tag.libelle}`
        )
        .join(' | ');
      addNewPageIfNeeded(10);
      doc.text(tagsLine, marginLeft, yPos);
      yPos += 8;
    }

    // 1) Información del jugador (données personnelles, équipe, etc.)
    if (includeProfileInfo) {
      const profileRows = [];
      pushNonEmptyRow(profileRows, 'Peso', player.peso, ' kg');
      pushNonEmptyRow(profileRows, 'Altura', player.taille, ' cm');
      if (player.dateNaissance) {
        pushNonEmptyRow(profileRows, 'Fecha de Nacimiento', formatDateForDisplay(player.dateNaissance));
      }
      pushNonEmptyRow(profileRows, 'Nacionalidad', player.nacionalidad);
      pushNonEmptyRow(profileRows, '2ª Nacionalidad', player.segundaNacionalidad);
      pushNonEmptyRow(profileRows, 'Origen Genético', player.origen_genetico);
      pushNonEmptyRow(profileRows, 'Selección', player.seleccionNacional);
      pushNonEmptyRow(profileRows, 'Equipo', player.equipo);
      pushNonEmptyRow(profileRows, 'Liga Principal', player.liga1);
      pushNonEmptyRow(profileRows, 'Liga Secundaria', player.liga2);
      if (player.groupes && player.groupes.length > 0) {
        const groupesLabel = player.groupes.map((g) => g.libelle).join(', ');
        pushNonEmptyRow(profileRows, 'Grupo(s)', groupesLabel);
      }
      if (profileRows.length > 0) {
        addNewPageIfNeeded(40);
        doc.autoTable({
          startY: yPos,
          head: [],
          body: profileRows.map((r) => [r[0], r[1]]),
          theme: 'grid',
          styles: { fontSize: 10, cellPadding: 2, lineColor: [0, 0, 0], lineWidth: 0.1 },
          margin: { left: marginLeft, right: marginRight, bottom: bottomMargin },
          tableWidth: 'auto'
        });
        yPos = doc.lastAutoTable.finalY + 10;
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(11);
      }
    }

    // 2) Características del jugador
    if (includeCaracteristicas) {
      const caracRows = [];
      pushNonEmptyRow(caracRows, 'Posición', player.poste);
      pushNonEmptyRow(caracRows, 'Mano Dominante', player.mano);
      pushNonEmptyRow(caracRows, 'Shooting Range', player.shootingRange);
      pushNonEmptyRow(caracRows, 'Rol Ofensivo', player.rolOfensivo);
      pushNonEmptyRow(caracRows, 'Rol Defensivo', player.rolDefensivo);
      pushNonEmptyRow(caracRows, 'Skills JE/JI', player.jeJiType);
      if (player.jeJiAttributes && player.jeJiAttributes.length > 0) {
        pushNonEmptyRow(caracRows, `Atributos (${player.jeJiType || ''})`, player.jeJiAttributes.join(', '));
      }
      pushNonEmptyRow(caracRows, 'Flag Behaviour', player.comportamiento || '');
      pushNonEmptyRow(caracRows, 'Injury Prone', player.injury || '');
      pushNonEmptyRow(caracRows, 'Nivel (actual)', player.nivel);
      pushNonEmptyRow(caracRows, 'Proyección', player.proyeccion);
      pushNonEmptyRow(caracRows, 'Comparación Pro', player.comparacionPro);
      if (caracRows.length > 0) {
        addNewPageIfNeeded(40);
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(0);
        doc.text('Características', marginLeft, yPos);
        yPos += 6;
        doc.autoTable({
          startY: yPos,
          head: [],
          body: caracRows.map((r) => [r[0], r[1]]),
          theme: 'grid',
          styles: { fontSize: 10, cellPadding: 2, lineColor: [0, 0, 0], lineWidth: 0.1 },
          margin: { left: marginLeft, right: marginRight, bottom: bottomMargin },
          tableWidth: 'auto'
        });
        yPos = doc.lastAutoTable.finalY + 10;
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(11);
      }
    }

    // 3) Experiencia profesional
    if (includeExperiencia) {
      const expRows = [];
      expRows.push(['NBA Experience', player.nbaExperience || '']);
      expRows.push(['ACB Experience', player.experienciaAcb || '']);
      expRows.push(['Experiencia Europa', player.experiencaEuropa || '']);
      pushNonEmptyRow(expRows, 'Año Draft', player.añoDraft);
      pushNonEmptyRow(expRows, 'Posición Draft', player.posicionDraft);
      pushNonEmptyRow(expRows, 'Pre-Draft Team', player.equipoPreDraft);
      pushNonEmptyRow(expRows, 'PIT', player.pit);
      pushNonEmptyRow(expRows, 'Eurocamp', player.eurocamp);
      if (expRows.length > 0) {
        addNewPageIfNeeded(30);
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(0);
        doc.text('Experiencia Profesional', marginLeft, yPos);
        yPos += 6;
        doc.autoTable({
          startY: yPos,
          head: [],
          body: expRows.map((r) => [r[0], r[1]]),
          theme: 'grid',
          styles: { fontSize: 10, cellPadding: 2, lineColor: [0, 0, 0], lineWidth: 0.1 },
          margin: { left: marginLeft, right: marginRight, bottom: bottomMargin },
          tableWidth: 'auto'
        });
        yPos = doc.lastAutoTable.finalY + 10;
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(11);
      }
    }

    // 4) Información contractual
    if (includeContrato) {
      const contractRows = [];
      pushNonEmptyRow(contractRows, 'Agencia', player.agencia);
      pushNonEmptyRow(contractRows, 'Rango Salarial', player.rangoPrecios);
      pushNonEmptyRow(contractRows, 'Contrato', player.contrato);
      pushNonEmptyRow(contractRows, 'Buyout', player.buyout);
      if (contractRows.length > 0) {
        addNewPageIfNeeded(20);
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(0);
        doc.text('Información Contractual', marginLeft, yPos);
        yPos += 6;
        doc.autoTable({
          startY: yPos,
          head: [],
          body: contractRows.map((r) => [r[0], r[1]]),
          theme: 'grid',
          styles: { fontSize: 10, cellPadding: 2, lineColor: [0, 0, 0], lineWidth: 0.1 },
          margin: { left: marginLeft, right: marginRight, bottom: bottomMargin },
          tableWidth: 'auto'
        });
        yPos = doc.lastAutoTable.finalY + 10;
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(11);
      }
    }

    // Fonction locale pour dessiner un bloc de texte long
    const drawTextBlockLocal = (text) => {
      const desiredFontFamily = 'helvetica';
      const desiredFontStyle = 'normal';
      const desiredFontSize = 11;
      doc.setFont(desiredFontFamily, desiredFontStyle);
      doc.setFontSize(desiredFontSize);
      yPos = drawTextBlock(
        doc,
        text,
        yPos,
        marginLeft,
        contentWidth,
        pageHeight,
        bottomMargin,
        desiredFontFamily,
        desiredFontStyle,
        desiredFontSize
      );
    };

    // Inclusion des informes sélectionnés
    if (checkedInformes.length > 0) {
      addNewPageIfNeeded(30);
      checkedInformes.forEach((inf, idx) => {
        addNewPageIfNeeded(40);
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(14);
        doc.text(`${idx + 1}. ${new Date(inf.fecha).toLocaleDateString()} (${inf.usuario_nombre})`, marginLeft, yPos);
        yPos += 6;
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(11);
        const scoutingType = formatScoutingType(inf.visu);
        if (scoutingType) {
          doc.text(`Scouting: ${scoutingType}`, marginLeft, yPos);
          yPos += 6;
        }
        if (inf.evento) {
          doc.text(`Evento: ${inf.evento}`, marginLeft, yPos);
          yPos += 6;
        }
        if (inf.equipo) {
          doc.text(`Equipo: ${inf.equipo}`, marginLeft, yPos);
          yPos += 6;
        }
        const detalles = convertHtmlToText(inf.detalles);
        if (detalles) {
          drawTextBlockLocal(detalles);
          yPos += 4;
        } else {
          yPos += 4;
        }
      });
    }

    // Inclusion des intels sélectionnés
    if (checkedIntels.length > 0) {
      addNewPageIfNeeded(30);
      checkedIntels.forEach((intel, idx) => {
        addNewPageIfNeeded(40);
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(14);
        doc.text(`${idx + 1}. ${new Date(intel.fecha).toLocaleDateString()} (${intel.usuario_nombre})`, marginLeft, yPos);
        yPos += 6;
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(11);
        if (intel.fuente) {
          doc.text(`Fuente: ${intel.fuente}`, marginLeft, yPos);
          yPos += 6;
        }
        const detallesIntel = convertHtmlToText(intel.detalles);
        if (detallesIntel) {
          drawTextBlockLocal(detallesIntel);
          yPos += 4;
        } else {
          yPos += 4;
        }
      });
    }

    const today = new Date();
const formattedDate = today.toLocaleDateString().replace(/\//g, '-');

const filenameParts = ['perfil'];

// Ajoute player.nom s'il est défini et non vide
if (player.nom && player.nom.trim() !== '') {
  filenameParts.push(player.nom.trim());
}

// Ajoute player.equipo s'il est défini et non vide
if (player.equipo && player.equipo.trim() !== '') {
  filenameParts.push(player.equipo.trim());
}

// Ajoute player.liga1 s'il est défini et non vide
if (player.liga1 && player.liga1.trim() !== '') {
  filenameParts.push(player.liga1.trim());
}

// Ajoute la date du jour
filenameParts.push(formattedDate);

const fileName = filenameParts.join('_') + '.pdf';
doc.save(fileName);

      };

  // Ouverture / fermeture du dialogue PDF
  const handleOpenPdfDialog = () => {
    setSelectAllSections(false);
    setIncludeProfileInfo(false);
    setIncludeCaracteristicas(false);
    setIncludeExperiencia(false);
    setIncludeContrato(false);
    setCheckedInformes([]);
    setCheckedIntels([]);
    setMasterCheckInformes(false);
    setMasterCheckIntels(false);
    setPdfDialogOpen(true);
  };

  const handleClosePdfDialog = () => {
    setPdfDialogOpen(false);
  };

  // Gestion de la sélection/déselection des informes
  const handleToggleInforme = (informe) => {
    setCheckedInformes((prev) => {
      const isChecked = prev.some((inf) => inf.id === informe.id);
      return isChecked ? prev.filter((inf) => inf.id !== informe.id) : [...prev, informe];
    });
  };

  // Gestion de la sélection/déselection des intels
  const handleToggleIntel = (intel) => {
    setCheckedIntels((prev) => {
      const isChecked = prev.some((item) => item.id === intel.id);
      return isChecked ? prev.filter((item) => item.id !== intel.id) : [...prev, intel];
    });
  };

  // Master check pour informes
  const handleMasterCheckInformes = (event) => {
    const newChecked = event.target.checked;
    setMasterCheckInformes(newChecked);
    setCheckedInformes(newChecked ? informeList : []);
  };

  // Master check pour intels
  const handleMasterCheckIntels = (event) => {
    const newChecked = event.target.checked;
    setMasterCheckIntels(newChecked);
    setCheckedIntels(newChecked ? intelList : []);
  };

  // ---------- Rendu ----------

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !player) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error || 'Error al cargar el jugador'}
      </Alert>
    );
  }

  // Layout MOBILE
  const isMobileLayout = isMobile;
  if (isMobileLayout) {
    const sections = [
      { id: 'info', name: 'Info', icon: <EditIcon />, available: true },
      { id: 'tecnica', name: 'Ficha Técnica', icon: <EditIcon />, available: hasTechnicaData },
      { id: 'informe', name: 'Informe', icon: <EditIcon />, available: hasInformeData && userRole !== 'entrenador' },
      { id: 'intel', name: 'Intel', icon: <EditIcon />, available: hasIntelData && userRole !== 'entrenador' },
      { id: 'pdp', name: 'PDP', icon: <EditIcon />, available: hasPDPData && userRole !== 'entrenador' }
    ].filter((s) => s.available);
    const getCurrentSection = () => sections[activeTab]?.id || 'info';
    const currentSection = getCurrentSection();

    return (
      <Box sx={{ pb: 8, minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
        <MobileProfileHeader
          player={player}
          avatarUrl={selectedImagePreview || `${mediaUrl}/photos/${player.id}.png?${avatarTimestamp}`}
          isEditing={isEditing}
          onEditPhoto={() => setOpenPhotoDialog(true)}
          onBack={() => navigate(-1)}
        />
        <Box sx={{ px: 2, py: 3 }}>
          {currentSection !== 'info'
            ? currentSection === 'tecnica'
              ? <TechnicaTab playerId={player.id} dateNaissance={player.dateNaissance} nom={player.nom} onDataLoaded={setHasTechnicaData} />
              : currentSection === 'informe'
                ? <InformeTab playerId={player.id} onDataLoaded={setHasInformeData} />
                : currentSection === 'intel'
                  ? <IntelTab playerId={player.id} onDataLoaded={setHasIntelData} />
                  : currentSection === 'pdp'
                    ? <PDPTab playerId={player.id} onDataLoaded={setHasPDPData} />
                    : null
            : <>
                <InformacionBasica
                  player={player}
                  editablePlayer={editablePlayer}
                  setEditablePlayer={setEditablePlayer}
                  isEditing={isEditing}
                  isMobile={true}
                  allGroups={allGroups}
                  handleChange={handleChange}
                  handleGroupChange={handleGroupChange}
                />
                <CaracteristicasJugador
                  player={player}
                  editablePlayer={editablePlayer}
                  setEditablePlayer={setEditablePlayer}
                  isEditing={isEditing}
                  isMobile={true}
                  handleChange={handleChange}
                />
                <SituacionActual
                  player={player}
                  editablePlayer={editablePlayer}
                  setEditablePlayer={setEditablePlayer}
                  isEditing={isEditing}
                  isMobile={true}
                  teams={teams}
                  leagues1={leagues1}
                  leagues2={leagues2}
                  handleChange={handleChange}
                />
                <ExperienciaProfesional
                  player={player}
                  editablePlayer={editablePlayer}
                  setEditablePlayer={setEditablePlayer}
                  isEditing={isEditing}
                  isMobile={true}
                  teams={teams}
                  handleChange={handleChange}
                />
                <InformacionContractual
                  player={player}
                  editablePlayer={editablePlayer}
                  setEditablePlayer={setEditablePlayer}
                  isEditing={isEditing}
                  isMobile={true}
                  agencies={agencies}
                  priceRanges={priceRanges}
                  contratos={contratos}
                  buyoutOptions={buyoutOptions}
                />
                <InformacionAdicional
                  player={player}
                  editablePlayer={editablePlayer}
                  setEditablePlayer={setEditablePlayer}
                  isEditing={isEditing}
                  isMobile={true}
                  handleChange={handleChange}
                />
              </>
          }
        </Box>
        <PhotoEditDialog
          open={openPhotoDialog}
          onClose={() => setOpenPhotoDialog(false)}
          onCapture={handlePhotoCapture}
          onSelect={handlePhotoSelect}
        />
        <MobileDrawerMenu
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          sections={sections}
          activeSection={activeTab}
          onSectionChange={setActiveTab}
        />
        {sections.length > 1 && (
          <Box sx={{ position: 'fixed', bottom: 10, left: 0, right: 0, zIndex: theme.zIndex.appBar }}>
            <Paper elevation={3}>
              <Tabs
                value={activeTab}
                onChange={(e, newValue) => setActiveTab(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{ backgroundColor: 'background.paper' }}
              >
                {sections.map((section) => (
                  <Tab key={section.id} label={section.name} />
                ))}
              </Tabs>
            </Paper>
          </Box>
        )}
        <AppBar
          position="fixed"
          color="primary"
          sx={{ top: 'auto', bottom: 58, backgroundColor: 'transparent', boxShadow: 'none' }}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
              {currentSection === 'info' && (
                <Button
                  variant="contained"
                  onClick={() => { isEditing ? handleUpdate() : setIsEditing(true); }}
                  fullWidth
                >
                  {isEditing ? 'Guardar' : 'Modificar'}
                </Button>
              )}
              {!isEditing && currentSection === 'info' && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PictureAsPdfIcon />}
                  onClick={handleOpenPdfDialog}
                  fullWidth
                >
                  Generar PDF
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <Dialog
          open={pdfDialogOpen}
          onClose={handleClosePdfDialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Generar PDF</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              Selecciona las secciones del perfil a incluir:
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAllSections}
                  onChange={(e) => {
                    setSelectAllSections(e.target.checked);
                    setIncludeProfileInfo(e.target.checked);
                    setIncludeCaracteristicas(e.target.checked);
                    setIncludeExperiencia(e.target.checked);
                    setIncludeContrato(e.target.checked);
                  }}
                />
              }
              label="Seleccionar todas las secciones"
            />
            <FormGroup sx={{ ml: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={includeProfileInfo} onChange={(e) => setIncludeProfileInfo(e.target.checked)} />}
                label="Información del jugador (datos personales, equipo...)"
              />
              <FormControlLabel
                control={<Checkbox checked={includeCaracteristicas} onChange={(e) => setIncludeCaracteristicas(e.target.checked)} />}
                label="Características del jugador"
              />
              <FormControlLabel
                control={<Checkbox checked={includeExperiencia} onChange={(e) => setIncludeExperiencia(e.target.checked)} />}
                label="Experiencia profesional"
              />
              <FormControlLabel
                control={<Checkbox checked={includeContrato} onChange={(e) => setIncludeContrato(e.target.checked)} />}
                label="Información contractual"
              />
            </FormGroup>
            <Divider sx={{ my: 2 }} />
            {informeList.length > 0 && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Selecciona los Informes a incluir:
                </Typography>
                <FormControlLabel
                  control={<Checkbox checked={masterCheckInformes} onChange={handleMasterCheckInformes} />}
                  label="Seleccionar todos los informes"
                />
                <FormGroup sx={{ ml: 2 }}>
                  {informeList.map((inf) => (
                    <FormControlLabel
                      key={inf.id}
                      control={
                        <Checkbox
                          checked={checkedInformes.some((item) => item.id === inf.id)}
                          onChange={() => handleToggleInforme(inf)}
                        />
                      }
                      label={`${new Date(inf.fecha).toLocaleDateString()} - ${inf.usuario_nombre} - ${inf.evento}`}
                    />
                  ))}
                </FormGroup>
                <Divider sx={{ my: 2 }} />
              </>
            )}
            {intelList.length > 0 && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Selecciona las INTEL a incluir:
                </Typography>
                <FormControlLabel
                  control={<Checkbox checked={masterCheckIntels} onChange={handleMasterCheckIntels} />}
                  label="Seleccionar todas las INTEL"
                />
                <FormGroup sx={{ ml: 2 }}>
                  {intelList.map((intel) => (
                    <FormControlLabel
                      key={intel.id}
                      control={
                        <Checkbox
                          checked={checkedIntels.some((item) => item.id === intel.id)}
                          onChange={() => handleToggleIntel(intel)}
                        />
                      }
                      label={`${new Date(intel.fecha).toLocaleDateString()} - ${intel.usuario_nombre} - ${intel.fuente}`}
                    />
                  ))}
                </FormGroup>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePdfDialog}>Cancelar</Button>
            <Button
              variant="contained"
              onClick={() => {
                handleClosePdfDialog();
                generatePDF();
              }}
            >
              Generar PDF
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  // Layout DESKTOP
  const sections = [
    { id: 'info', name: 'Info', icon: <EditIcon />, available: true },
    { id: 'tecnica', name: 'Ficha Técnica', icon: <EditIcon />, available: hasTechnicaData },
    { id: 'informe', name: 'Informe', icon: <EditIcon />, available: hasInformeData && userRole !== 'entrenador' },
    { id: 'intel', name: 'Intel', icon: <EditIcon />, available: hasIntelData && userRole !== 'entrenador' },
    { id: 'pdp', name: 'PDP', icon: <EditIcon />, available: hasPDPData && userRole !== 'entrenador' }
  ].filter((s) => s.available);
  const getCurrentSection = () => sections[activeTab]?.id || 'info';
  const currentSection = getCurrentSection();

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Box sx={{ width: 300, pr: 2 }}>
        <Paper sx={{ p: 3, mb: 2, textAlign: 'center' }}>
          <Avatar
            src={selectedImagePreview || `${mediaUrl}/photos/${player.id}.png?${avatarTimestamp}`}
            alt={player.nom}
            sx={{ width: 200, height: 200, mx: 'auto', mb: 2 }}
            onClick={() => isEditing && setOpenPhotoDialog(true)}
          />
          <Typography variant="h6" gutterBottom>
            {player.nom}
          </Typography>
          <Box sx={{ mb: 2 }}>
            {player.type && (
              <Chip
                label={player.type === 'cantera' ? 'Cantera' : 'Pro'}
                sx={{
                  backgroundColor: player.type === 'cantera' ? '#FF8C00' : '#007FFF',
                  color: '#fff'
                }}
              />
            )}
          </Box>
          {player?.url && (
            <Button
              variant="outlined"
              startIcon={<OpenInNew />}
              href={player.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ mb: 2 }}
              fullWidth
            >
              Ficha Estadística
            </Button>
          )}
          {currentSection === 'info' && (
            <>
              <Button
                variant="contained"
                onClick={() => { isEditing ? handleUpdate() : setIsEditing(true); }}
                fullWidth
                sx={{ mb: 1 }}
              >
                {isEditing ? 'Guardar' : 'Modificar'}
              </Button>
              {!isEditing && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PictureAsPdfIcon />}
                  onClick={handleOpenPdfDialog}
                  fullWidth
                >
                  Generar PDF
                </Button>
              )}
            </>
          )}
        </Paper>
        {sections.length > 1 && (
          <Paper sx={{ p: 1 }}>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              orientation="vertical"
              variant="scrollable"
            >
              {sections.map((section) => (
                <Tab key={section.id} label={section.name} />
              ))}
            </Tabs>
          </Paper>
        )}
      </Box>
      <Box sx={{ flex: 1, p: 2 }}>
        {currentSection !== 'info'
          ? currentSection === 'tecnica'
            ? (
              <TechnicaTab
                playerId={player.id}
                dateNaissance={player.dateNaissance}
                nom={player.nom}
                onDataLoaded={setHasTechnicaData}
              />
            )
            : currentSection === 'informe'
              ? <InformeTab playerId={player.id} onDataLoaded={setHasInformeData} />
              : currentSection === 'intel'
                ? <IntelTab playerId={player.id} onDataLoaded={setHasIntelData} />
                : currentSection === 'pdp'
                  ? <PDPTab playerId={player.id} onDataLoaded={setHasPDPData} />
                  : null
          : (
            <>
              <InformacionBasica
                player={player}
                editablePlayer={editablePlayer}
                setEditablePlayer={setEditablePlayer}
                isEditing={isEditing}
                isMobile={false}
                allGroups={allGroups}
                handleChange={handleChange}
                handleGroupChange={handleGroupChange}
              />
              <CaracteristicasJugador
                player={player}
                editablePlayer={editablePlayer}
                setEditablePlayer={setEditablePlayer}
                isEditing={isEditing}
                isMobile={false}
                handleChange={handleChange}
              />
              <SituacionActual
                player={player}
                editablePlayer={editablePlayer}
                setEditablePlayer={setEditablePlayer}
                isEditing={isEditing}
                isMobile={false}
                teams={teams}
                leagues1={leagues1}
                leagues2={leagues2}
                handleChange={handleChange}
              />
              <ExperienciaProfesional
                player={player}
                editablePlayer={editablePlayer}
                setEditablePlayer={setEditablePlayer}
                isEditing={isEditing}
                isMobile={false}
                teams={teams}
                handleChange={handleChange}
              />
              <InformacionContractual
                player={player}
                editablePlayer={editablePlayer}
                setEditablePlayer={setEditablePlayer}
                isEditing={isEditing}
                isMobile={false}
                agencies={agencies}
                priceRanges={priceRanges}
                contratos={contratos}
                buyoutOptions={buyoutOptions}
              />
              <InformacionAdicional
                player={player}
                editablePlayer={editablePlayer}
                setEditablePlayer={setEditablePlayer}
                isEditing={isEditing}
                isMobile={false}
                handleChange={handleChange}
              />
            </>
          )
        }
      </Box>

      <PhotoEditDialog
        open={openPhotoDialog}
        onClose={() => setOpenPhotoDialog(false)}
        onCapture={handlePhotoCapture}
        onSelect={handlePhotoSelect}
      />

      <Dialog
        open={pdfDialogOpen}
        onClose={handleClosePdfDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Generar PDF</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Selecciona las secciones del perfil a incluir:
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAllSections}
                onChange={(e) => {
                  setSelectAllSections(e.target.checked);
                  setIncludeProfileInfo(e.target.checked);
                  setIncludeCaracteristicas(e.target.checked);
                  setIncludeExperiencia(e.target.checked);
                  setIncludeContrato(e.target.checked);
                }}
              />
            }
            label="Seleccionar todas las secciones"
          />
          <FormGroup sx={{ ml: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={includeProfileInfo} onChange={(e) => setIncludeProfileInfo(e.target.checked)} />}
              label="Información del jugador (datos personales, equipo...)"
            />
            <FormControlLabel
              control={<Checkbox checked={includeCaracteristicas} onChange={(e) => setIncludeCaracteristicas(e.target.checked)} />}
              label="Características del jugador"
            />
            <FormControlLabel
              control={<Checkbox checked={includeExperiencia} onChange={(e) => setIncludeExperiencia(e.target.checked)} />}
              label="Experiencia profesional"
            />
            <FormControlLabel
              control={<Checkbox checked={includeContrato} onChange={(e) => setIncludeContrato(e.target.checked)} />}
              label="Información contractual"
            />
          </FormGroup>
          <Divider sx={{ my: 2 }} />
          {informeList.length > 0 && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Selecciona los Informes a incluir:
              </Typography>
              <FormControlLabel
                control={<Checkbox checked={masterCheckInformes} onChange={handleMasterCheckInformes} />}
                label="Seleccionar todos los informes"
              />
              <FormGroup sx={{ ml: 2 }}>
                {informeList.map((inf) => (
                  <FormControlLabel
                    key={inf.id}
                    control={
                      <Checkbox
                        checked={checkedInformes.some((item) => item.id === inf.id)}
                        onChange={() => handleToggleInforme(inf)}
                      />
                    }
                    label={`${new Date(inf.fecha).toLocaleDateString()} - ${inf.usuario_nombre} - ${inf.evento}`}
                  />
                ))}
              </FormGroup>
              <Divider sx={{ my: 2 }} />
            </>
          )}
          {intelList.length > 0 && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Selecciona las INTEL a incluir:
              </Typography>
              <FormControlLabel
                control={<Checkbox checked={masterCheckIntels} onChange={handleMasterCheckIntels} />}
                label="Seleccionar todas las INTEL"
              />
              <FormGroup sx={{ ml: 2 }}>
                {intelList.map((intel) => (
                  <FormControlLabel
                    key={intel.id}
                    control={
                      <Checkbox
                        checked={checkedIntels.some((item) => item.id === intel.id)}
                        onChange={() => handleToggleIntel(intel)}
                      />
                    }
                    label={`${new Date(intel.fecha).toLocaleDateString()} - ${intel.usuario_nombre} - ${intel.fuente}`}
                  />
                ))}
              </FormGroup>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePdfDialog}>Cancelar</Button>
          <Button
            variant="contained"
            onClick={() => {
              handleClosePdfDialog();
              generatePDF();
            }}
          >
            Generar PDF
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// ----------------- Composants auxiliaires -----------------

// Composant : capture photo (caméra)
const PhotoCapture = ({ onCapture }) => {
  const handlePhotoCapture = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const resized = await resizeImageFile(file);
        onCapture(resized);
      } catch (err) {
        console.error('Error resizing captured photo', err);
      }
    }
  };
  return (
    <Box>
      <input
        accept="image/*"
        id="photo-capture"
        type="file"
        capture="environment"
        onChange={handlePhotoCapture}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-capture">
        <Button variant="contained" component="span" startIcon={<PhotoCamera />} fullWidth>
          Tomar foto
        </Button>
      </label>
    </Box>
  );
};

// Composant : sélection photo (galerie)
const PhotoSelection = ({ onSelect }) => {
  const handlePhotoSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const resized = await resizeImageFile(file);
        onSelect(resized);
      } catch (err) {
        console.error('Error resizing selected photo', err);
      }
    }
  };
  return (
    <Box>
      <input
        accept="image/*"
        id="photo-select"
        type="file"
        onChange={handlePhotoSelect}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-select">
        <Button variant="contained" component="span" startIcon={<ImageIcon />} fullWidth>
          Seleccionar foto
        </Button>
      </label>
    </Box>
  );
};

// Menu latéral pour mobile
const MobileDrawerMenu = ({ open, onClose, sections, activeSection, onSectionChange }) => {
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { width: '80%', maxWidth: 360 } }}
    >
      <Box sx={{ width: '100%' }}>
        <List>
          <ListItem>
            <Typography variant="h6">Secciones</Typography>
          </ListItem>
          <Divider />
          {sections.map((section, index) => (
            <ListItem key={section.name} disablePadding>
              <ListItemButton
                selected={activeSection === index}
                onClick={() => {
                  onSectionChange(index);
                  onClose();
                }}
              >
                <ListItemIcon>{section.icon}</ListItemIcon>
                <ListItemText primary={section.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

// En-tête pour mobile
const MobileProfileHeader = ({ player, avatarUrl, isEditing, onEditPhoto, onBack }) => {
  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <IconButton edge="start" onClick={onBack}>
          <ArrowBack />
        </IconButton>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          py: 2
        }}>
          <Box sx={{ position: 'relative' }}>
            <Avatar
              src={avatarUrl}
              alt={player?.nom}
              sx={{ width: 120, height: 120, border: '3px solid white', boxShadow: 1 }}
            />
            {isEditing && (
              <IconButton
                sx={{
                  position: 'absolute',
                  bottom: -10,
                  right: -10,
                  backgroundColor: 'background.paper',
                  boxShadow: 1,
                  '&:hover': { backgroundColor: 'background.paper' }
                }}
                onClick={onEditPhoto}
              >
                <Edit />
              </IconButton>
            )}
          </Box>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {player?.nom}
          </Typography>
          {player?.type && (
            <Chip
              label={player.type === 'cantera' ? 'Cantera' : 'Pro'}
              sx={{
                backgroundColor: player.type === 'cantera' ? '#FF8C00' : '#007FFF',
                color: '#fff',
                mt: 1
              }}
            />
          )}
          {player?.url && (
            <Button
              variant="outlined"
              startIcon={<OpenInNew />}
              href={player.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ mt: 1, mb: 2, backgroundColor: 'white' }}
            >
              Ficha Estadistica
            </Button>
          )}
          {player?.tags && player.tags.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, justifyContent: 'center', mb: 2 }}>
              {player.tags.map((tag) => (
                <Tooltip key={tag.id} title={tag.source === 'big_board' ? 'Ranking' : 'Seguimiento'} placement="top" arrow>
                  <Chip
                    label={tag.libelle}
                    sx={{
                      backgroundColor: tag.source === 'big_board' ? '#1565c0' : '#c62828',
                      color: '#fff'
                    }}
                  />
                </Tooltip>
              ))}
            </Box>
          )}
          {player?.groupes && player.groupes.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, justifyContent: 'center', mt: 1 }}>
              {player.groupes.map((group) => (
                <Chip
                  key={group.id}
                  label={group.libelle}
                  size="small"
                  sx={{
                    backgroundColor: group.code_couleur || 'grey',
                    color: '#fff'
                  }}
                />
              ))}
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

// Dialog pour changer la photo
const PhotoEditDialog = ({ open, onClose, onCapture, onSelect }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Cambiar foto</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
          <PhotoCapture
            onCapture={(file) => {
              onCapture(file);
              onClose();
            }}
          />
          <PhotoSelection
            onSelect={(file) => {
              onSelect(file);
              onClose();
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlayerProfile;
