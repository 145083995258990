import React, { createContext, useContext, useState } from 'react';

const SeguimientoTableContext = createContext();

export const SeguimientoTableProvider = ({ children }) => {
  const [expandedTags, setExpandedTags] = useState(new Set());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tags, setTags] = useState([]);

  const value = {
    expandedTags,
    setExpandedTags,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
    selectedPlayer,
    setSelectedPlayer,
    selectedUser,
    setSelectedUser,
    tags,
    setTags,
  };

  return (
    <SeguimientoTableContext.Provider value={value}>
      {children}
    </SeguimientoTableContext.Provider>
  );
};

export const useSeguimientoTable = () => {
  const context = useContext(SeguimientoTableContext);
  if (context === undefined) {
    throw new Error('useSeguimientoTable must be used within a SeguimientoTableProvider ');
  }
  return context;
};
